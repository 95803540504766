import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from './Loader';

function FullPageLoader({ className }) {
  return (
    <div className={classNames('hero is-fullheight is-light is-bold is-large', className)}>
      <div className="hero-body has-text-centered">
        <div className="columns">
          <div className="column is-full has-text-centered">
            <Loader isLarge className="title is-2" />
          </div>
        </div>
      </div>
      <div className="hero-foot" />
    </div>
  );
}

FullPageLoader.propTypes = {
  className: PropTypes.string,
};
export default FullPageLoader;
