import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import Title from '../../../components/Title';
import Background from '../../../components/Layout';
import Button from '../../../components/Button';

import themes from '../../../libs/themes';
import BackImage from '../../../images/arrow-back-gray-icn.svg';
import Header from '../../../components/Header';
import OPERATIONS from './ChooseOperation.query.graphql';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Operation from './components/Operation';
import SalesOrder from '../../../components/SalesOrder';
import { setMachine, setProgram } from '../../../actions';

function ChooseOperation() {
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const { jobId } = useParams();
  useEffect(() => {
    dispatch(setProgram(null));
    dispatch(setMachine(null));
  }, [dispatch]);

  const { stationId, hmiConfiguration } = useSelector(({ config }) => config);
  const { OPERATION_SORT: sort } = hmiConfiguration;

  const variables = { stationId, jobId, sort };
  const { data, loading, error } = useQuery(OPERATIONS, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { job, phases } = data;

  function onClick({ _id, _program, _machine }) {
    history.push(`/configuration/definition/${jobId}/${_id}`);
    dispatch(setProgram(_program));
    dispatch(setMachine(_machine));
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Operations - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth onClick={() => history.push('/configuration/order')}>
                  <img src={BackImage} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            {/* HEAD */}
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-1 w700">
                  <FormattedMessage
                    id="app.pages.configuration.operations.title"
                    defaultMessage="Choose operation ({value, number} found)"
                    values={{ value: phases.length }}
                  />
                </h1>
                <p className="subtitle is-2">
                  {job.name}{' '}
                  <small className="fg-secondary">
                    <SalesOrder salesOrderId={job.salesOrderId} meta={job.meta} />
                  </small>
                </p>
              </div>
            </div>
            {/* DATA */}
            <div className="columns">
              <div className="column is-10 is-offset-1">
                {phases.map((phase) => (
                  <Operation key={phase._id} phase={phase} onClick={onClick} />
                ))}
                {phases.length === 0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.configuration.operations.no_phase"
                      defaultMessage="There is no phase, contact responsible"
                    />
                  </Title.H2>
                )}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ChooseOperation.defaultProps = {};
ChooseOperation.propTypes = {};

export default ChooseOperation;
