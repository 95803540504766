import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useSubscription } from 'react-apollo';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { ThemeProvider } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';

import Background from '../../../components/Layout';
import DEFINITION from './Definition.query.graphql';
import CREATE_SESSION from './Definition.mutation.graphql';
import MACHINE_UPDATED from './Machine.subscription.graphql';
import Button from '../../../components/Button';
import onError from '../../../libs/error-logger';
import SafetyViewer from '../../../components/Safety';
import { resetTeam, setLoading, setMachine, setProgram } from '../../../actions';
import themes from '../../../libs/themes';
import BackImage from '../../../images/arrow-back-gray-icn.svg';
import Phase from './components/Phase';
import Header from '../../../components/Header';
import IdleTimeout from '../../../libs/idle-timeout';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../components/LoadingIndicator';
import toast from '../../../libs/toast';

/**
 * Operation Definition page, displays information about operation
 *
 * @returns {*}
 * @constructor
 */
function Definition() {
  const intl = useIntl();
  const theme = themes.default;
  const dispatch = useDispatch();
  const history = useHistory();
  const [safety, setSafety] = useState(false);
  const { jobId, phaseId } = useParams();

  const { hmiId, stationId, machineId, team, programId, hmiConfiguration } = useSelector(({ config }) => config);
  const { CHOOSE_PHASE, HMI_TIMEOUT } = hmiConfiguration;
  const variables = { hmiId, stationId, machineId, programId, phaseId, team };
  const { data, loading, error } = useQuery(DEFINITION, {
    variables: { phaseId, machineId, where: { _stations: stationId } },
  });
  const [mutation] = useMutation(CREATE_SESSION, { variables });
  useSubscription(MACHINE_UPDATED, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, machine, safetyCards } = data;

  function onLogout() {
    history.push('/');
  }

  async function onSubmit() {
    if (machine && machine.status !== 'IDLE') {
      const { session } = machine;
      toast({
        title: intl.formatMessage(
          {
            id: 'app.pages.configuration.definition.error.machine_busy',
            defaultMessage: 'Sorry, machine is busy, {station} @ {area}, worker: {worker}',
          },
          { station: session.station.name, area: session.area.name, worker: session.worker.name }
        ),
        icon: 'warning',
      });
      return false;
    }
    dispatch(setLoading(true));
    try {
      const { data } = await mutation();
      console.log(data);
      const { session } = data;
      const { HMI_MODE } = session.phase.configuration;
      if (HMI_MODE === 'WORKFLOW') {
        history.push(`/application/step/${phaseId}/${session._id}`);
      }
      if (HMI_MODE === 'FREE') {
        history.push(`/application/menu/${phaseId}/${session._id}`);
      }
      IdleTimeout.start({ timeout: HMI_TIMEOUT, callback: onLogout });
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }
  async function onBack() {
    dispatch(setProgram());
    dispatch(setMachine());
    dispatch(resetTeam());
    if (CHOOSE_PHASE === true) {
      return history.push(`/configuration/operation/${jobId}`);
    }
    return history.push('/configuration/operator');
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Operation details - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              {/* BACK BUTTON */}
              <Choose>
                <When condition={CHOOSE_PHASE}>
                  <div className="column is-1">
                    <Button isFullWidth onClick={onBack} data-cy="configuration-definition-back">
                      <img src={BackImage} alt="" />
                    </Button>
                  </div>
                </When>
                <Otherwise>
                  <div className="column is-2">
                    <Button isFullWidth onClick={() => history.push('/')}>
                      <FormattedMessage id="app.shared.signout" defaultMessage="Sign out" />
                    </Button>
                  </div>
                </Otherwise>
              </Choose>
              {/* START SESSION BUTTON */}
              <div className={classNames('column is-6', CHOOSE_PHASE && 'is-offset-2', !CHOOSE_PHASE && 'is-offset-1')}>
                <Choose>
                  <When condition={safetyCards.length > 0}>
                    <Button
                      isFullWidth
                      theme={themes.primary.buttons}
                      onClick={() => setSafety(true)}
                      data-cy="configuration-definition-safety"
                    >
                      <FormattedMessage
                        id="app.pages.configuration.definition.button.show_safety"
                        defaultMessage="Safety and start"
                      />
                    </Button>
                  </When>
                  <Otherwise>
                    <Button
                      isFullWidth
                      theme={themes.primary.buttons}
                      onClick={() => onSubmit()}
                      data-cy="configuration-definition-start"
                    >
                      <FormattedMessage
                        id="app.pages.configuration.definition.button.start_session"
                        defaultMessage="Start session"
                      />
                    </Button>
                  </Otherwise>
                </Choose>
              </div>
              <div className="column is-3">
                <Button
                  isFullWidth
                  onClick={() => history.push(`/configuration/advanced/menu/${jobId}/${phaseId}`)}
                  data-cy="configuration-definition-advanced"
                >
                  <FormattedMessage id="app.pages.configuration.definition.button.advanced" defaultMessage="Advanced" />
                </Button>
              </div>
            </div>
          }
        >
          <Phase phase={phase} />
        </Background>
      </ThemeProvider>
      {safety && (
        <div className="is-overlay" style={{ background: 'white' }}>
          <SafetyViewer safetyCards={safetyCards} onReject={() => setSafety(false)} onAccept={() => onSubmit()} />
        </div>
      )}
    </>
  );
}

Definition.defaultProps = {};
Definition.propTypes = {};

export default Definition;
