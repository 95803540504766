import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getMainDefinition } from 'apollo-utilities';

import config from '../config';
import store from '../store';

console.log('Starting graphql on', config.graphql.api, 'with websockets on', config.graphql.ws);

const cache = new InMemoryCache({
  addTypename: true,
  dataIdFromObject: (result) => {
    if (result._id && result.__typename) {
      return result.__typename + result._id; // eslint-disable-line no-underscore-dangle
    }
    return null;
  },
});
const wsLink = new WebSocketLink({
  uri: config.graphql.ws,
  options: {
    reconnect: true,
  },
});
const httpLink = new HttpLink({
  uri: config.graphql.api,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);
const middlewareLink = setContext(() => {
  const state = store.getState();
  const token = state?.user.token;
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return {
    headers: {},
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    // errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  // link: middlewareLink.concat(errorLink.concat(link)),
  link: middlewareLink.concat(link),
  cache: cache.restore(window.__APOLLO_CLIENT__),
  defaultOptions,
});

export default client;
