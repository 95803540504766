import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';

import NOTES from './Notes.query.graphql';
import NotesBody from './components/NotesBody';
import Loader from '../Loader';

const Container = styled.div`
  .notification {
    color: #455a64;
    border-radius: 0 0 0.4rem 0.4rem;
  }
`;

/**
 * The phase notes
 * @constructor
 */
function Notes() {
  const { phaseId } = useParams();
  const { loading, data, error } = useQuery(NOTES, { variables: { phaseId } });
  if (loading && !data) {
    return <Loader isLarge message={null} />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase } = data;
  return (
    <Container>
      <h4 className="title is-4 w500">
        <FormattedMessage id="app.components.notes.title" defaultMessage="Notes" />
      </h4>
      <div className="separator" />
      <div className="notification is-paddingless">
        <NotesBody phase={phase} />
      </div>
    </Container>
  );
}

Notes.defaultProps = {};
Notes.propTypes = {};
export default Notes;
