import React from 'react';
import PropTypes from 'prop-types';
import NumberFormatter from '@agile-factory/component-number-formatter';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import STEP_HEADER from './StepHeader.query.graphql';
import themes from '../libs/themes';
import Subtitle from './Subtitle';
import SalesOrder from './SalesOrder';
import LoadingIndicator from './LoadingIndicator';

function StepHeader({ type }) {
  const { phaseId } = useParams();
  const variables = { phaseId };
  const { data, loading, error } = useQuery(STEP_HEADER, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase } = data;
  return (
    <>
      <Subtitle.S3 theme={themes.secondary} style={{ marginBottom: '.5rem' }}>
        {/* NAME */}
        {type === 'INPUT' && <strong>{`${phase.sort} :: ${phase.name}`}</strong>}
        {/* JOB */} {phase.job.name}
        <small>
          {' '}
          <SalesOrder salesOrderId={phase.job.salesOrderId} meta={phase.job.meta} />
        </small>
      </Subtitle.S3>
      <Subtitle.S4 theme={themes.secondary}>
        {/* PRODUCT */}
        {phase.product && (
          <>
            <i className="icon-product" /> {phase.product.code}
          </>
        )}
        {/* PRODUCTIVITY */}
        {type !== 'PRODUCTION' && (
          <>
            {phase.produced && <span> | </span>}
            <i className="icon-giphy-measurement-units" />
            <NumberFormatter value={phase.produced} />
            {' / '}
            <NumberFormatter value={phase.qty} />
          </>
        )}
      </Subtitle.S4>
    </>
  );
}

StepHeader.defaultProps = {
  type: 'STEP',
};
StepHeader.propTypes = {
  type: PropTypes.oneOf(['STEP', 'INPUT', 'PRODUCTION']),
};
export default StepHeader;
