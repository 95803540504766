import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Container = styled.div`
  .main {
    height: calc(100vh - 25rem - 2.7rem - 4rem);
    object,
    img {
      margin: auto;
      height: 100%;
      width: 100%;
      object-fit: contain;
      max-height: 100%;
    }
  }
  .notification {
    color: #455a64;
    padding: 1.5rem;
    overflow-y: auto;
    border-radius: 0 0 0.4rem 0.4rem !important;
    div {
      white-space: pre-line;
    }
  }
`;

function SafetyCard({ safetyCard }) {
  const { description, card } = safetyCard;
  return (
    <Container className="columns is-fullwidth">
      {card && (
        <div className="column is-7 has-text-left">
          <h4 className="title is-4 w500">
            <FormattedMessage id="app.components.safety_viewer.card.title" defaultMessage="Card" />
          </h4>
          <div className="main">
            {card.mimeType === 'application/pdf' ? (
              <object data={card.link} type={card.mimeType} className="image">
                <embed src={card.link} type={card.mimeType} />
              </object>
            ) : (
              <img src={card.link} alt="" className="image" />
            )}
          </div>
        </div>
      )}
      <div className="column has-text-left">
        <h4 className="title is-4 w500">
          <FormattedMessage id="app.components.safety_viewer.card.notes.title" defaultMessage="Notes" />
        </h4>
        <div className="separator" />
        <div className="notification">
          <div>
            {description ? (
              <p className="title is-4">{description}</p>
            ) : (
              <p className="title is-4">
                <FormattedMessage id="app.components.safety_viewer.card.notes.placeholder" defaultMessage="No note" />
              </p>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

SafetyCard.defaultProps = {};
SafetyCard.propTypes = {
  safetyCard: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    description: PropTypes.string,
    card: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      mimeType: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
export default SafetyCard;
