import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import SafetyCard from './SafetyCard';
import themes from '../../libs/themes';
import Background from '../Layout';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Header from '../Header';
import Title from '../Title';

function SafetyViewer({ safetyCards, onAccept, onReject }) {
  const theme = themes.default;
  const [page, setPage] = useState(1);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Operation details - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasClock={false} hasSessionInfo={false} hasUserInfo>
              <>
                <div className="column is-4 has-text-centered">
                  <Title.H3>{safetyCards[page - 1].name}</Title.H3>
                </div>
                <div className="column is-4 has-text-right">
                  <Title.H3>
                    <FormattedMessage
                      id="app.components.safety_viewer.title"
                      defaultMessage="Card {page, number} of {total, number}"
                      values={{ page, total: safetyCards.length }}
                    />
                  </Title.H3>
                </div>
              </>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-2">
                <Button isFullWidth onClick={onReject} data-cy="components-safety-back">
                  <FormattedMessage id="app.shared.cancel" defaultMessage="Cancel" />
                </Button>
              </div>
              <div className="column is-6 is-offset-1">
                {page === safetyCards.length ? (
                  <Button
                    isFullWidth
                    theme={themes.primary.buttons}
                    onClick={onAccept}
                    data-cy="components-safety-start"
                  >
                    <FormattedMessage
                      id="app.components.safety_viewer.button.start_session"
                      defaultMessage="Start session"
                    />
                  </Button>
                ) : (
                  <Button
                    isFullWidth
                    theme={themes.primary.buttons}
                    onClick={() => setPage(page + 1)}
                    data-cy="components-safety-next"
                  >
                    <FormattedMessage id="app.shared.next" defaultMessage="Next" />
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <SafetyCard safetyCard={safetyCards[page - 1]} />
        </Background>
      </ThemeProvider>
    </>
  );
}

SafetyViewer.defaultProps = {};
SafetyViewer.propTypes = {
  safetyCards: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      description: PropTypes.string,
      card: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        mimeType: PropTypes.string.isRequired,
      }),
    })
  ),
};

export default SafetyViewer;
