import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import NumberFormatter from '@agile-factory/component-number-formatter';

import Background from '../../../../components/Layout/Background';
import themes from '../../../../libs/themes';
import Header from '../../../../components/Header';
import CONTAINER_CONTENT from './ContainerContent.query.graphql';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';
import QuantityMenu from './components/QuantityMenu';

function ContainerContent() {
  const [showQuantityMenu, setShowQuantityMenu] = useState(false);
  const { containerId, produced } = useParams();
  const history = useHistory();
  const variables = { containerId };
  const { data, loading, error } = useQuery(CONTAINER_CONTENT, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { container } = data;
  const remaining = container.current - (produced || 0);
  const theme = themes.neutralGrey;
  return (
    <>
      <Helmet>
        <title>Container Content - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img src={require('../../../../images/arrow-back-gray-icn.svg').default} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns has-text-centered is-multiline">
              {/* ITEM */}
              <div className="column is-half is-offset-3">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.push(`/application/container-item/${containerId}`)}
                  data-cy="application-container_content-item"
                >
                  <FormattedMessage id="app.pages.application.container_content.item" defaultMessage="Item" />
                  {container.item && (
                    <span className="fg-secondary" style={{ marginLeft: '1rem' }}>
                      {container.item.code} :: <small>{container.item.name}</small>
                    </span>
                  )}
                </Button>
              </div>
              {/* BATCH */}
              <div className="column is-half is-offset-3">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.push(`/application/container-batch/${containerId}`)}
                  data-cy="application-container_content-batch"
                >
                  <FormattedMessage id="app.pages.application.container_content.batch" defaultMessage="Batch" />
                  {container.batch && (
                    <span className="fg-secondary" style={{ marginLeft: '1rem' }}>
                      {container.batch}
                    </span>
                  )}
                </Button>
              </div>
              {/* CONTENT */}
              <div className="column is-half is-offset-3">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => setShowQuantityMenu(true)}
                  data-cy="application-container_content-quantity"
                >
                  <FormattedMessage id="app.pages.application.container_content.quantity" defaultMessage="Quantity" />
                  {container.current > 0 && (
                    <span className="fg-secondary" style={{ marginLeft: '1rem' }}>
                      <strong>
                        <NumberFormatter value={remaining > 0 ? remaining : 0} /> {container.unit}
                      </strong>
                      {container.capacity && (
                        <>
                          <small>
                            /<NumberFormatter value={container.capacity} />
                          </small>{' '}
                          <small className="fg-bluish-grey">{container.percentage || 0}%</small>
                        </>
                      )}
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
      {showQuantityMenu && <QuantityMenu onClose={() => setShowQuantityMenu(false)} container={container} />}
    </>
  );
}

ContainerContent.defaultProps = {};
ContainerContent.propTypes = {};
export default ContainerContent;
