import Button from '../Button';
import themes from '../../libs/themes';
import React from 'react';
import PropTypes from 'prop-types';

function DigitButton({ value, onClick }) {
  return (
    <Button
      isFullWidth
      theme={themes.default.buttons}
      onClick={() => onClick(value)}
      data-cy="components-pin-button"
      data-cy-value={value}
    >
      {value}
    </Button>
  );
}

DigitButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DigitButton;
