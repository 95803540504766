import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import Background from '../../../../components/Layout/Background';
import themes from '../../../../libs/themes';
import Header from '../../../../components/Header';
import CONTAINER_QUANTITY from './ContainerQuantity.query.graphql';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';

function ContainerQuantity() {
  const { containerId } = useParams();
  const history = useHistory();
  const variables = { containerId };
  const { data, loading, error } = useQuery(CONTAINER_QUANTITY, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { container } = data;

  const theme = themes.neutralGrey;
  return (
    <>
      <Helmet>
        <title>Container Quantity - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img src={require('../../../../images/arrow-back-gray-icn.svg').default} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns has-text-centered is-multiline">
              {/* LOAD */}
              <div className="column is-half is-offset-3">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.push(`/application/container-load/${containerId}`)}
                  data-cy="application-container_quantity-load"
                >
                  <FormattedMessage id="app.pages.application.container_quantity.load" defaultMessage="Load" />
                </Button>
              </div>
              {/* UNLOAD */}
              <div className="column is-half is-offset-3">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.push(`/application/container-unload/${containerId}`)}
                  data-cy="application-container_quantity-unload"
                >
                  <FormattedMessage id="app.pages.application.container_quantity.unload" defaultMessage="Unload" />
                </Button>
              </div>
              {/* EMPTY */}
              <div className="column is-half is-offset-3">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.push(`/application/container-unload/${containerId}`)}
                  data-cy="application-container_quantity-unload"
                >
                  <FormattedMessage id="app.pages.application.container_quantity.empty" defaultMessage="Empty" />
                </Button>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerQuantity.defaultProps = {};
ContainerQuantity.propTypes = {};
export default ContainerQuantity;
