import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import ErrorTracking from '@agile-factory/module-error-tracking-browser';

import store from '../store';
import graphql from '../libs/graphql';
import Routes from './Routes';
import FullPageLoader from '../components/FullPageLoader';
import config from '../config';
import { language, messages } from '../config/localization';

const persistor = persistStore(store, null, () => {
  console.log('Store rehydrated');
});

/**
 * React Application
 *
 * @returns {*}
 * @constructor
 */
function Application() {
  useEffect(() => {
    ErrorTracking.initialize({
      customer: config.customer,
      dsn: config.sentry,
      release: config.build,
      environment: config.env,
    });
  });
  return (
    <PersistGate
      persistor={persistor}
      loading={
        <IntlProvider locale={language} messages={messages}>
          <FullPageLoader />
        </IntlProvider>
      }
    >
      <IntlProvider locale={language} messages={messages}>
        <ApolloProvider client={graphql}>
          <Provider store={store}>
            <Routes />
            <div id="modal-container" />
          </Provider>
        </ApolloProvider>
      </IntlProvider>
    </PersistGate>
  );
}
Application.propTypes = {};

export default Application;
