import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import themes from '../libs/themes';

const Tag = styled.span`
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  white-space: nowrap;
  border-radius: 4rem;
  padding: 0.5rem 1.5rem;
  font-size: 4rem;
  font-weight: 700;
  background-color: ${({ theme, isLight }) => (isLight ? rgba(theme.bg, 0.65) : theme.bg)};
  color: ${({ theme }) => theme.fg};
  ${({ isUpperCase }) => isUpperCase && 'text-transform: uppercase;'}
`;

const SmallTag = styled(Tag)`
  font-size: 1.6rem;
`;

Tag.defaultProps = {
  theme: themes.default,
  isLight: false,
  isUpperCase: false,
};
Tag.propTypes = {
  isLight: PropTypes.bool,
  isUpperCase: PropTypes.bool,
  theme: PropTypes.shape({
    bg: PropTypes.string.isRequired,
    fg: PropTypes.string.isRequired,
  }),
};
export { Tag as default, SmallTag };
