import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Title from '../../../../../components/Title';
import { MediumButton } from '../../../../../components/Button';
import OnScreenKeyboard from '../../../../../components/OnScreenKeyboard';
import themes, { buttons } from '../../../../../libs/themes';
import Subtitle from '../../../../../components/Subtitle';
import toast from '../../../../../libs/toast';

function ContainerUnloadStep1({ qty, setQty, container, setStep }) {
  const intl = useIntl();
  const inputEl = useRef(null);
  const history = useHistory();
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);
  function onNext() {
    if (isNaN(qty)) {
      toast({ title: intl.formatMessage({ id: 'app.shared.nan' }), icon: 'error' });
      return;
    }
    if (Number(qty) <= 0) {
      toast({ title: intl.formatMessage({ id: 'app.shared.gt_zero' }), icon: 'error' });
      return;
    }
    if (container.current - qty < 0) {
      toast({
        title: intl.formatMessage({
          id: 'app.pages.application.container_unload.qty.error.less_than_zero',
          defaultMessage: 'Sorry, total quantity will be less than zero',
        }),
        icon: 'error',
      });
      return;
    }
    setStep(2);
  }
  return (
    <>
      <div className="columns">
        <div className="column">
          <Title.H1>
            <FormattedMessage id="app.pages.application.container_unload.qty.title" defaultMessage="How many units?" />
          </Title.H1>
          <Subtitle.S3 theme={themes.secondary} style={{ marginBottom: '.5rem' }}>
            {container.name}
          </Subtitle.S3>
          {container.item && (
            <Subtitle.S4 theme={themes.secondary}>
              {container.item.code} :: {container.item.name}
            </Subtitle.S4>
          )}
        </div>
        <div className="column is-3">
          <div className="columns">
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.default}
                onClick={() => history.goBack()}
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.cancel" defaultMessage="Cancel" />
              </MediumButton>
            </div>
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.productionBlue}
                onClick={onNext}
                data-cy="application-button-next"
              >
                <FormattedMessage id="app.shared.next" defaultMessage="Next" />
              </MediumButton>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-centered">
          <div className="separator" />
          <input
            name="qty"
            data-cy="application-container_unload-qty-input"
            id="qty"
            type="text"
            step="any"
            min="0"
            className="title is-1 is-double"
            placeholder="0"
            value={qty || 0}
            onFocus={() => inputEl.current && inputEl.current.select()}
            autoFocus
            ref={inputEl}
            onInput={(event) => setQty(+event.target.value)}
            style={{
              color: '#455a64',
              width: '100%',
              border: 'none',
              outline: 'none',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          />
        </div>
      </div>
      <OnScreenKeyboard inputNode={inputEl.current} />
    </>
  );
}

ContainerUnloadStep1.defaultProps = {
  qty: null,
};
ContainerUnloadStep1.propTypes = {
  qty: PropTypes.number,
  setQty: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    current: PropTypes.number.isRequired,
    capacity: PropTypes.number,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ContainerUnloadStep1;
