import React from 'react';
import { Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery, useSubscription } from 'react-apollo';
import { ThemeProvider } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import NextStepButton from './components/NextStepButton';
import PreviousStepButton from './components/PreviousStepButton';
import themes, { statuses } from '../../../libs/themes';
import Background from '../../../components/Layout/Background';
import * as Bodies from '../components';
import SetStepMutation from './components/SetStep';
import STEP_QUERY from './Step.query.graphql';
import CYCLE_RECEIVED from './Step.subscription.graphql';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import CenteredLayout from '../../../layouts/CenteredLayout';
import LoadingIndicator from '../../../components/LoadingIndicator';

function Step() {
  const history = useHistory();
  const location = useLocation();
  const { phaseId, sessionId, stepId } = useParams();
  const variables = { phaseId };
  const { data, loading, error } = useQuery(STEP_QUERY, { variables });
  useSubscription(CYCLE_RECEIVED, { variables });
  const { features } = useSelector(({ config }) => config);
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase } = data;
  const { STEPS = [] } = phase.configuration;
  if (STEPS.length === 0) {
    throw new Error('Error, steps empty');
  }
  const index = stepId ? STEPS.findIndex(({ _id }) => _id === stepId) || 0 : 0;
  const step = STEPS[index];
  const { name, status } = step;
  const theme = statuses[status];
  // Force redirect to first step
  if (!stepId) {
    return (
      <Redirect
        to={{
          pathname: `/application/step/${phaseId}/${sessionId}/${step._id}`,
          state: { from: location },
        }}
      />
    );
  }
  return (
    <SetStepMutation status={status} timeout={800}>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasMenu />}
          foot={
            <div className="columns">
              <div className="column">{index > 0 && <PreviousStepButton steps={STEPS} />}</div>
              <div className="column">
                {status === 'MATERIAL' && (
                  <Button
                    isFullWidth
                    theme={themes.materialViolet.buttons}
                    onClick={() => history.push(`/application/picked/${phaseId}/${sessionId}/${stepId}`)}
                    data-cy="application-button-picked"
                  >
                    <FormattedMessage id="app.pages.application.material.buttons.picked" defaultMessage="Picked" />
                  </Button>
                )}
                {status === 'SETUP' && phase?.session?.machine && features.includes('MACHINE:INTEGRATION') && (
                  <Button
                    isFullWidth
                    theme={themes.setupYellow.buttons}
                    onClick={() =>
                      history.push(`/application/machine/${phaseId}/${sessionId}/${phase.session._machine}`)
                    }
                    data-cy="application-button-machine"
                  >
                    <FormattedMessage id="app.pages.application.setup.buttons.machine" defaultMessage="Machine" />
                  </Button>
                )}
              </div>
              <div className="column">
                <Choose>
                  <When condition={index + 1 === STEPS.length}>
                    <Button
                      isFullWidth
                      theme={themes.primary.buttons}
                      onClick={() => history.push(`/application/delivery/${phaseId}/${sessionId}/${stepId}`)}
                      data-cy="application-button-next"
                    >
                      <FormattedMessage id="app.shared.delivery" defaultMessage="Delivery" />
                    </Button>
                  </When>
                  <Otherwise>
                    <NextStepButton steps={STEPS} />
                  </Otherwise>
                </Choose>
              </div>
            </div>
          }
        >
          <Choose>
            <When condition={status === 'APPROVAL'}>
              <Bodies.ApprovalBody phase={phase} stepName={name} />
            </When>
            <When condition={status === 'MATERIAL'}>
              <Bodies.MaterialBody phase={phase} stepName={name} />
            </When>
            <When condition={status === 'PRODUCTION'}>
              <Bodies.ProductionBody phase={phase} stepName={name} />
            </When>
            <When condition={status === 'SETUP'}>
              <Bodies.SetupBody phase={phase} stepName={name} />
            </When>
            <When condition={status === 'CHECK'}>
              <Bodies.CheckBody phase={phase} stepName={name} />
            </When>
            <Otherwise>{status} UNKNOWN STATUS</Otherwise>
          </Choose>
        </Background>
      </ThemeProvider>
    </SetStepMutation>
  );
}

Step.defaultProps = {};
Step.propTypes = {};

export default Step;
