import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import classNames from 'classnames';

import Button from '../Button';
import themes from '../../libs/themes';
import Background from '../Layout';
import Title from '../Title';
import toast from '../../libs/toast';
import DigitButton from './DigitButton';

function VerifyPin({ title, verify, onSuccess, onCancel }) {
  const intl = useIntl();
  const [pin, setPin] = useState('');
  const [isError, setIsError] = useState(false);

  const theme = themes.default;

  function onButtonPress(value) {
    setPin(pin + value);
    if ((pin + value).length < 4) {
      return;
    }
    if (verify(pin + value)) {
      return onSuccess();
    }
    setPin('');
    setIsError(true);
    toast({
      title: intl.formatMessage({ id: 'app.components.verify_pin.alert.error', defaultMessage: 'Sorry, wrong pin' }),
      icon: 'error',
    });
    setTimeout(() => setIsError(false), 800);
  }

  return ReactDOM.createPortal(
    <div className="is-overlay" style={{ background: 'white' }}>
      <ThemeProvider theme={theme}>
        <Background
          hasSessions={false}
          head={
            <div className="columns">
              <div className="column has-text-centered">
                <Title.H3>{title}</Title.H3>
              </div>
            </div>
          }
          foot={
            <div className="columns">
              <div className="column is-4">
                <Button isFullWidth onClick={onCancel} data-cy="components-pin-cancel">
                  <FormattedMessage id="app.shared.cancel" defaultMessage="Cancel" />
                </Button>
              </div>
            </div>
          }
        >
          <div className={classNames('columns', isError && 'animated shake duration400')}>
            <div className="column is-half is-offset-one-quarter">
              <textarea
                rows="1"
                readOnly
                className={classNames('title is-2', isError && 'animated shake duration400 validation-error')}
                value={Array.from({ length: pin.length }, () => '*').join('')}
                data-cy="components-pin-input"
              />
              <div className="columns">
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="1" />
                </div>
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="2" />
                </div>
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="3" />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="4" />
                </div>
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="5" />
                </div>
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="6" />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="7" />
                </div>
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="8" />
                </div>
                <div className="column">
                  <DigitButton onClick={onButtonPress} value="9" />
                </div>
              </div>
              <div className="columns">
                <div className="column is-one-third">
                  <DigitButton onClick={() => setPin(pin.slice(0, -1))} value="<" />
                </div>
                <div className="column is-one-third">
                  <DigitButton onClick={onButtonPress} value="0" />
                </div>
              </div>
            </div>
          </div>
        </Background>
      </ThemeProvider>
    </div>,
    document.getElementById('modal-container')
  );
}

VerifyPin.defaultProps = {};
VerifyPin.propTypes = {
  verify: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default VerifyPin;
