import React from 'react';
import PropTypes from 'prop-types';

function Version({ color }) {
  return (
    <div
      style={{
        position: 'absolute',
        right: '2.2rem',
        bottom: '.1rem',
        color,
        fontSize: '1.2rem',
      }}
    >
      v{process.env.REACT_APP_VERSION} ({process.env.REACT_APP_BUILD})
    </div>
  );
}

Version.defaultProps = {
  color: 'white',
};
Version.propTypes = {
  color: PropTypes.string,
};
export default Version;
