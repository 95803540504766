import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import Background from './Layout/Background';
import Title from './Title';
import Button from './Button';
import themes from '../libs/themes';
import confirmAction from '../libs/confirm_action';

function HeaderMenu({ onClose }) {
  const intl = useIntl();
  const theme = themes.default;

  const history = useHistory();
  const { phaseId, sessionId, stepId = '' } = useParams();

  async function onIssue() {
    await confirmAction({
      confirm: (value) => {
        switch (value) {
          case 'PROBLEM':
          case true:
            history.push(`/application/problem/${phaseId}/${sessionId}/${stepId}`);
            break;
          case 'ERROR':
          case false:
            history.push(`/application/error/picker/${phaseId}/${sessionId}/${stepId}`);
            break;
          default:
            throw new Error('Type not recognized');
        }
      },
      options: {
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({
          id: 'app.components.header.problem.alert.PROBLEM',
          defaultMessage: '🧑‍🏭 I can fix it',
        }),
        denyButtonText: intl.formatMessage({
          id: 'app.components.header.problem.alert.ERROR',
          defaultMessage: '🆘 I need help',
        }),

        title: intl.formatMessage({
          id: 'app.components.header.problem.alert.title',
          defaultMessage: 'Can you fix it or do you need help?',
        }),
        text: intl.formatMessage({
          id: 'app.components.header.problem.alert.text',
          defaultMessage:
            'If you choose the second option, the operation will be locked waiting for help from a responsible',
        }),
      },
    });
  }

  return ReactDOM.createPortal(
    <div className="is-overlay" style={{ background: 'white' }}>
      <ThemeProvider theme={theme}>
        <Background
          hasSessions={false}
          head={
            <div className="columns">
              <div className="column has-text-centered">
                <Title.H3>
                  <FormattedMessage id="app.components.header_menu.title" defaultMessage="Menu" />
                </Title.H3>
              </div>
            </div>
          }
          foot={
            <div className="columns">
              <div className="column is-3">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={onClose}
                  data-cy="application-button-close-menu"
                >
                  <FormattedMessage id="app.shared.close" defaultMessage="Close" />
                </Button>
              </div>
            </div>
          }
        >
          <div className="columns has-text-centered is-multiline">
            {/* PAUSE */}
            <div className="column is-half is-offset-3">
              <Button
                isFullWidth
                theme={themes.pauseAzure.buttons}
                onClick={() => history.push(`/application/pause/${phaseId}/${sessionId}/${stepId}`)}
                data-cy="application-button-pause"
              >
                <FormattedMessage id="app.shared.pause" defaultMessage="Pause" />
              </Button>
            </div>
            {/* ISSUE */}
            <div className="column is-half is-offset-3">
              <Button
                isFullWidth
                theme={themes.errorRed.buttons}
                onClick={onIssue}
                // onClick={() => history.push(`/application/error/picker/${phaseId}/${sessionId}/${stepId}`)}
                data-cy="application-button-issue"
              >
                <FormattedMessage id="app.shared.issue" defaultMessage="Issue" />
              </Button>
            </div>
            {/* SUPPLY */}
            <div className="column is-half is-offset-3">
              <Button
                isFullWidth
                theme={themes.materialViolet.buttons}
                onClick={() => history.push(`/application/supply/${phaseId}/${sessionId}/${stepId}`)}
                data-cy="application-button-supply"
              >
                <FormattedMessage id="app.components.header_menu.supply" defaultMessage="Supply alerts" />
              </Button>
            </div>
            {/* DOCS */}
            <div className="column is-half is-offset-3">
              <Button
                isFullWidth
                theme={themes.primary.buttons}
                onClick={() => history.push(`/application/info/${phaseId}/${sessionId}/${stepId}`)}
                data-cy="application-button-info"
              >
                <FormattedMessage id="app.shared.docs" defaultMessage="Docs" />
              </Button>
            </div>
            {/* CONTAINERS */}
            <div className="column is-half is-offset-3">
              <Button
                isFullWidth
                theme={themes.default.buttons}
                onClick={() => history.push(`/application/containers`)}
                data-cy="application-button-containers"
              >
                <FormattedMessage id="app.components.header_menu.containers" defaultMessage="Containers" />
              </Button>
            </div>
          </div>
        </Background>
      </ThemeProvider>
    </div>,
    document.getElementById('modal-container')
  );
}

HeaderMenu.defaultProps = {};
HeaderMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default HeaderMenu;
