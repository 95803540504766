import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-paginating';

import PickingItem from './PickingItem';
import Button from '../../../../../components/Button';

const PAGE_LIMIT = 8;

function PickingList({ list }) {
  const [currentPage, setCurrentPage] = useState(1);
  function paginate(list, pageNumber) {
    const start = pageNumber - 1; // because pages logically start with 1, but technically with 0
    return list.slice(start * PAGE_LIMIT, (start + 1) * PAGE_LIMIT);
  }
  const total = list.length;
  const pageCount = Math.ceil(total / PAGE_LIMIT);
  return (
    <Pagination total={total} limit={PAGE_LIMIT} pageCount={pageCount} currentPage={currentPage}>
      {({ pages, currentPage, hasNextPage, hasPreviousPage, previousPage, nextPage, getPageItemProps }) => (
        <div className="columns is-vcentered">
          <div className="column is-narrow is-flex">
            {hasPreviousPage && (
              <Button
                isFullWidth
                {...getPageItemProps({
                  pageValue: previousPage,
                  onPageChange: setCurrentPage,
                })}
              >
                {'<'}
              </Button>
            )}
          </div>
          <div className="column">
            <div className="columns is-multiline">
              {paginate(list, currentPage).map((picking) => (
                <PickingItem key={picking._id} picking={picking} />
              ))}
            </div>
          </div>
          <div className="column is-narrow is-flex">
            {hasNextPage && (
              <Button
                isFullWidth
                {...getPageItemProps({
                  pageValue: nextPage,
                  onPageChange: setCurrentPage,
                })}
              >
                {'>'}
              </Button>
            )}
          </div>
        </div>
      )}
    </Pagination>
  );
}

PickingList.defaultProps = {};
PickingList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default PickingList;
