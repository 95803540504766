import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import styled from 'styled-components';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import classNames from 'classnames';

import SalesOrder from '../../../../components/SalesOrder';
import ListElement from '../../../../components/ListElement';
import themes from '../../../../libs/themes';

const StyledListElement = styled(ListElement)`
  small.is-late {
    color: ${themes.errorRed.border};
    font-weight: bold;
  }
  .subtitle:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

/**
 * Job
 *
 * @param job
 * @param history
 * @returns {*}
 * @constructor
 */
function Job({ job, history }) {
  const isLate = job.deadline && isBefore(new Date(job.deadline), startOfDay(new Date()));
  const total = job.phases.length;
  return (
    <StyledListElement
      onClick={() => history.push(`/configuration/operation/${job._id}`)}
      theme={themes.default.listElement}
      data-cy="configuration-orders-order"
      data-cy-id={job._id}
    >
      <div className="columns is-vcentered is-fullwidth is-marginless">
        <div className="column is-narrow">
          <ul>
            <li className="title is-4">{job.name}</li>
            <li className="subtitle is-4 fg-secondary">
              <SalesOrder salesOrderId={job.salesOrderId} meta={job.meta} />
            </li>
          </ul>
        </div>
        <div className="column truncate">
          <ul>
            {job.product && <li className="title is-4 fg-secondary has-text-weight-normal">{job.product.code}</li>}
            {job.customer && <li className="subtitle is-4 fg-secondary">{job.customer.name}</li>}
          </ul>
        </div>
        {job.deadline && (
          <div className="column is-narrow fg-secondary">
            <small className={classNames(isLate && 'is-late')}>
              <FormattedDate value={job.deadline} year="numeric" month="long" day="2-digit" />
            </small>
          </div>
        )}
      </div>

      {job.phases.length > 0 && (
        <div className="column">
          <FormattedMessage
            id="app.shared.operation_assigned.short"
            defaultMessage="{total, plural, =0 {No operation} one {{total, number} operation} other {{total, number} operations}}"
            values={{ total }}
          />
        </div>
      )}
    </StyledListElement>
  );
}

Job.defaultProps = {};
Job.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    salesOrderId: PropTypes.string,
    batch: PropTypes.string,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    meta: PropTypes.shape({
      customerCode: PropTypes.string,
    }),
    customer: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    phases: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      })
    ),
  }),
};
export default compose(withRouter)(Job);
