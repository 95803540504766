import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-paginating';

import Program from './Program';
import Button from '../../../../../components/Button';

const PAGE_LIMIT = 8;

class Programs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }
  static defaultProps = {
    programs: [],
  };
  static propTypes = {
    onClick: PropTypes,
  };
  handlePageChange = (page) => {
    this.setState({
      currentPage: page,
    });
  };
  paginate(programs, pageNumber) {
    const start = pageNumber - 1; // because pages logically start with 1, but technically with 0
    return programs.slice(start * PAGE_LIMIT, (start + 1) * PAGE_LIMIT);
  }
  render() {
    const { currentPage } = this.state;
    const { programs, onClick } = this.props;
    const total = programs.length;
    const pageCount = Math.ceil(total / PAGE_LIMIT);
    return (
      <Pagination total={total} limit={PAGE_LIMIT} pageCount={pageCount} currentPage={currentPage}>
        {({
          pages,
          currentPage,
          hasNextPage,
          hasPreviousPage,
          previousPage,
          nextPage,
          totalPages,
          getPageItemProps,
        }) => (
          <div className="columns">
            <div className="column is-narrow is-flex">
              {hasPreviousPage && (
                <Button
                  isFullWidth
                  {...getPageItemProps({
                    pageValue: previousPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {'<'}
                </Button>
              )}
            </div>
            <div className="column">
              <div className="columns is-multiline">
                {this.paginate(programs, currentPage).map((program) => (
                  <Program program={program} key={program._id} onClick={() => onClick(program._id)} />
                ))}
              </div>
            </div>
            <div className="column is-narrow is-flex">
              {hasNextPage && (
                <Button
                  isFullWidth
                  {...getPageItemProps({
                    pageValue: nextPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {'>'}
                </Button>
              )}
            </div>
          </div>
        )}
      </Pagination>
    );
  }
}

Programs.defaultProps = {};
Programs.propTypes = {};
export default Programs;
