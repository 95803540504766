import React from 'react';
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';

import Avatar from '../Avatar';
import Loader from '../Loader';
import HMI from './UserInfo.query.graphql';

/**
 * Returns User Informations
 * @constructor
 */
function UserInfo() {
  const user = useSelector(({ user }) => user);
  const { loading, error, data } = useQuery(HMI, { variables: { hmiId: window.HMI_ID } });
  if (loading && !data) {
    return (
      <article className="media">
        <div className="media-content" style={{ marginTop: '1rem' }}>
          <div className="content">
            <Loader message={null} />
          </div>
        </div>
      </article>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { hmi } = data;
  return (
    <div className="columns">
      <div className="column is-narrow">
        <figure className="image is-48x48">
          <Avatar size="4.8rem" name={user.worker.name || '-'} src={user.worker.image} />
        </figure>
      </div>
      <div className="column">
        <h4 className="title is-4 w700">{user.worker.name || '-'}</h4>
        <p className="subtitle is-6 fg-secondary">
          {hmi.name || '-'} {hmi.description}
        </p>
      </div>
    </div>
  );
}

UserInfo.propTypes = {};
export default UserInfo;
