import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import DOCS from './Docs.query.graphql';
import PickingList from '../PickingList';
import Attachment from './components/Attachment';
import LoadingIndicator from '../LoadingIndicator';

/**
 * Docs container, it displays the docs (BOM and phase documents, they are managed differently)
 * @param filterFn Filter documents with a function
 * @constructor
 */
function Docs({ filterFn }) {
  const { phaseId } = useParams();
  const variables = { phaseId };
  const { data, loading, error } = useQuery(DOCS, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase } = data;
  const docs = [
    ...(phase.docs || []),
    ...(phase.job?.docs || []),
    ...(phase.product?.docs || []),
    ...(phase.job.product?.docs || []),
  ];
  return (
    <div>
      <h4 className="title is-4 w500">
        <FormattedMessage id="app.components.docs.title" defaultMessage="Attachments" />
      </h4>
      <div className="separator" style={{ marginBottom: '1.5rem' }} />
      <div className="columns is-multiline">
        <Choose>
          {/* If there are no docs, or phase.bom should not be shown or is empty, show a placeholder */}
          <When condition={docs.filter(filterFn).length === 0 && phase.picking.length === 0}>
            <div className="column">
              <h4 className="title is-4">
                <FormattedMessage id="app.components.docs.no_file_found" defaultMessage="No files found" />
              </h4>
            </div>
          </When>
          <Otherwise>
            {docs.filter(filterFn).map((doc) => (
              <Attachment key={doc._id} doc={doc} />
            ))}
            <PickingList phase={phase} picking={phase.picking} />
          </Otherwise>
        </Choose>
      </div>
    </div>
  );
}

Docs.defaultProps = {
  filterFn: () => true,
};
Docs.propTypes = {
  filterFn: PropTypes.func,
};

export default Docs;
