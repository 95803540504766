import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import Docs from '../../../../components/Docs';
import Notes from '../../../../components/Notes';
import Tag, { SmallTag } from '../../../../components/Tag';
import Timer from '../../../../components/Timer';
import themes from '../../../../libs/themes';
import Title from '../../../../components/Title';
import StepHeader from '../../../../components/StepHeader';
import CHECK from './CheckBody.query.graphql';
import LoadingIndicator from '../../../../components/LoadingIndicator';

/**
 * Approval page
 *
 * @param stepName
 * @constructor
 */
function CheckBody({ stepName }) {
  const { phaseId, sessionId } = useParams();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(CHECK, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;
  return (
    <>
      <Helmet>
        <title>Check - Agile Factory</title>
      </Helmet>
      <div className="columns">
        <div className="column">
          <SmallTag isUpperCase isLight theme={themes.checkGreen.buttons}>
            {stepName || <FormattedMessage id="app.shared.check" defaultMessage="Check" />}
          </SmallTag>
          <Title.H1>
            {phase.sort} :: {phase.name}
          </Title.H1>
          <StepHeader />
        </div>
        <div className="column is-narrow">
          <Tag theme={themes.checkGreen.buttons}>
            <i className="icon-giphy-time" />
            <Timer time={session.timers.CHECK || 0} active />
            <small style={{ color: 'rgba(255,255,255,.85)' }}>
              {' / '}
              <Timer time={session.timers.TOTAL || 0} active />
            </small>
          </Tag>
        </div>
      </div>
      <div className="columns">
        <div className="column is-half has-text-left">
          <Docs filterFn={(doc) => ['BLUEPRINT', 'CHECK'].includes(doc.type)} />
        </div>
        <div className="column is-half has-text-left">
          <Notes />
        </div>
      </div>
    </>
  );
}

CheckBody.defaultProps = {
  stepName: 'CHECK',
};
CheckBody.propTypes = {
  stepName: PropTypes.string,
};

export default CheckBody;
