/**
 * Created by andrea on 27/06/2017.
 */
import PropTypes from 'prop-types';
const safetyCard = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  cards: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
});
/**
 * @description phase PropTypes
 */
const phase = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  produced: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  producedByCycle: PropTypes.number,
  description: PropTypes.string,
  timers: PropTypes.shape({
    production: PropTypes.number.isRequired,
  }),
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  safetyCards: PropTypes.arrayOf(safetyCard),
});
/**
 * @description User PropTypes
 */
const user = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  image: PropTypes.string,
  role: PropTypes.string,
});

export { phase as phasePropTypes, user as userPropTypes, safetyCard as safetyCardPropTypes };
