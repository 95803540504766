import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Title from '../../../../../components/Title';
import { MediumButton } from '../../../../../components/Button';
import OnScreenKeyboard from '../../../../../components/OnScreenKeyboard';
import themes, { buttons } from '../../../../../libs/themes';
import Subtitle from '../../../../../components/Subtitle';

function ContainerBatchStep1({ batch, setBatch, container, setStep }) {
  const intl = useIntl();
  const inputEl = useRef(null);
  const history = useHistory();
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <Title.H1>
            <FormattedMessage
              id="app.pages.application.container_batch.batch.title"
              defaultMessage="What is the batch?"
            />
          </Title.H1>
          <Subtitle.S3 theme={themes.secondary} style={{ marginBottom: '.5rem' }}>
            {container.name}
          </Subtitle.S3>
          {container.item && (
            <Subtitle.S4 theme={themes.secondary}>
              {container.item.code} :: {container.item.name}
            </Subtitle.S4>
          )}
        </div>
        <div className="column is-4">
          <div className="columns">
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.default}
                onClick={() => history.goBack()}
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.cancel" defaultMessage="Cancel" />
              </MediumButton>
            </div>
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.default}
                onClick={() => setBatch('')}
                data-cy="application-button-clear"
              >
                <FormattedMessage id="app.shared.clear" defaultMessage="Clear" />
              </MediumButton>
            </div>
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.productionBlue}
                onClick={() => setStep(2)}
                data-cy="application-button-next"
              >
                <FormattedMessage id="app.shared.next" defaultMessage="Next" />
              </MediumButton>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-centered">
          <div className="separator" />
          <input
            name="batch"
            data-cy="application-container_batch-batch-input"
            id="batch"
            type="text"
            value={batch ?? container.batch}
            className="title is-1 is-double"
            onFocus={() => inputEl.current && inputEl.current.select()}
            autoFocus
            ref={inputEl}
            onInput={(event) => setBatch(event.target.value)}
            placeholder={intl.formatMessage({
              id: 'app.pages.application.container_batch.batch.title.placeholder',
              defaultMessage: 'Insert batch',
            })}
            style={{
              color: '#455a64',
              width: '100%',
              border: 'none',
              outline: 'none',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          />
        </div>
      </div>
      <OnScreenKeyboard inputNode={inputEl.current} />
    </>
  );
}

ContainerBatchStep1.defaultProps = {
  batch: null,
};
ContainerBatchStep1.propTypes = {
  batch: PropTypes.string,
  setBatch: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    batch: PropTypes.string,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  setStep: PropTypes.func.isRequired,
};

export default ContainerBatchStep1;
