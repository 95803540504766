import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-apollo';

import themes from '../../../../libs/themes';
import Background from '../../../../components/Layout/Background';
import Header from '../../../../components/Header';
import Button from '../../../../components/Button';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import SUPPLY_SETTINGS from './SupplySettings.query.graphql';
import SUPPLY_MUTATION from './SupplySettings.mutation.graphql';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import PickingItem from './components/PickingItem';
import AlertThreshold from './components/AlertThreshold';
import toast from '../../../../libs/toast';
import onError from '../../../../libs/error-logger';

function SupplySettings() {
  const intl = useIntl();
  const [qty, setQty] = useState();
  const { sessionId } = useParams();
  const history = useHistory();
  const variables = { sessionId };
  const { data, loading, error } = useQuery(SUPPLY_SETTINGS, { variables });
  const [mutate] = useMutation(SUPPLY_MUTATION, { variables });
  const theme = themes.materialViolet;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session } = data;
  const { picking } = session;

  async function onSubmit() {
    const value = qty ?? session.alertThreshold;
    debugger;
    if (isNaN(value)) {
      toast({ title: intl.formatMessage({ id: 'app.shared.nan' }), icon: 'error' });
      return;
    }
    try {
      const variables = { alertThreshold: value };
      await mutate({ variables });
      toast({ title: intl.formatMessage({ id: 'app.shared.save_success' }) });
    } catch (error) {
      onError(error);
    }
  }
  return (
    <>
      <Helmet>
        <title>Supply Settings - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img src={require('../../../../images/arrow-back-gray-icn.svg').default} alt="" />
                </Button>
              </div>
              <div className="column is-4 is-offset-7">
                <Button isFullWidth theme={themes.default.buttons} data-cy="application-button-save" onClick={onSubmit}>
                  <FormattedMessage id="app.shared.save" defaultMessage="Save" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.supply_settings.title"
                    defaultMessage="Supply alerts settings"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage id="app.shared.status" defaultMessage="Status" />
                  {': '}
                  {session.enableContainerUnload ? (
                    <strong className="has-text-success">
                      <FormattedMessage id="app.shared.enabled" defaultMessage="Enabled" />
                    </strong>
                  ) : (
                    <strong className="has-text-danger">
                      <FormattedMessage id="app.shared.disabled" defaultMessage="Disabled" />
                    </strong>
                  )}
                </Subtitle.S3>
              </div>
            </div>
            <div className="columns">
              {/* PICKING LIST */}
              <div className="column">
                <Title.H3>
                  <FormattedMessage
                    id="app.pages.application.supply_settings.picking_list"
                    defaultMessage="Picking list"
                  />
                </Title.H3>
                <div className="separator" style={{ marginBottom: '1rem' }} />
                {picking.map((pickingItem) => (
                  <PickingItem key={pickingItem._id} picking={pickingItem} />
                ))}
              </div>
              {/* NOTICE */}
              <div className="column">
                <Title.H3>
                  <FormattedMessage
                    id="app.pages.application.supply_settings.threshold"
                    defaultMessage="Alert threshold (units)"
                  />
                </Title.H3>
                <div className="separator" />
                <AlertThreshold qty={qty ?? session.alertThreshold} setQty={setQty} />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplySettings.defaultProps = {};
SupplySettings.propTypes = {};
export default SupplySettings;
