import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Operators from './Operators';
import Avatar from '../../../../../components/Avatar';
import { buttons } from '../../../../../libs/themes';
import { chunk } from '../../../../../libs/helpers';

const Container = styled.div`
  position: relative;
  .image {
    margin-bottom: 0.8rem;
    width: 18rem;
  }
  .selected {
    position: absolute;
    top: -1.75rem;
    right: 0.15rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(245, 245, 245, 0.5);
    transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out 250ms;
    color: ${buttons.checkGreen.fg};
    background-color: ${buttons.checkGreen.bg};
  }
`;

function OperatorsGrouped({ operators, pageSize }) {
  const { index, jobId, phaseId } = useParams();
  const history = useHistory();
  const { team } = useSelector(({ config }) => config);
  if (operators.length <= pageSize) {
    return <Operators operators={operators} pageSize={pageSize} />;
  }

  const count = Math.ceil(operators.length / pageSize);
  const result = chunk(operators, count);
  if (index) {
    return <Operators operators={result[index]} pageSize={pageSize} />;
  }
  return (
    <div className="columns">
      <div className="column">
        <div className="columns is-multiline">
          {result.map((operators, index) => (
            <Container className="column is-3 has-text-centered" key={operators[0]._id}>
              <figure className="image">
                <a onClick={() => history.push(`/configuration/advanced/team/${jobId}/${phaseId}/${index}`)}>
                  <Avatar
                    size="12.8rem"
                    value={`${operators[0].firstName[0]} ... ${operators[operators.length - 1].firstName[0]}`}
                    textSizeRatio={4}
                  />
                </a>
                {team.some((member) => operators.some(({ _id }) => _id === member)) && <span className="selected" />}
              </figure>
              <h4 className="title is-4 w500 has-text-centered">
                {`${operators[0].firstName} - ${operators[operators.length - 1].firstName}`}
              </h4>
            </Container>
          ))}
        </div>
      </div>
    </div>
  );
}

OperatorsGrouped.defaultProps = {
  pageSize: 8,
};
OperatorsGrouped.propTypes = {
  pageSize: PropTypes.number,
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pin: PropTypes.string,
      role: PropTypes.string,
      image: PropTypes.string,
    })
  ),
};

export default OperatorsGrouped;
