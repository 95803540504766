import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Background from '../../../components/Layout/Background';
import Button from '../../../components/Button';
import ProductionBody from '../components/production/ProductionBody';
import themes from '../../../libs/themes';
import Header from '../../../components/Header';
import Wrapper from './components/Wrapper';

function Production() {
  const history = useHistory();
  const { phaseId, sessionId } = useParams();
  return (
    <Wrapper status="PRODUCTION">
      <Background
        head={<Header hasMenu />}
        foot={
          <div className="columns">
            {/* MENU */}
            <div className="column">
              <Button
                isFullWidth
                onClick={() => history.push(`/application/menu/${phaseId}/${sessionId}`)}
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.menu" defaultMessage="Menu" />
              </Button>
            </div>
            <div className="column is-offset-4">
              <Button
                isFullWidth
                theme={themes.primary.buttons}
                onClick={() => history.push(`/application/check/${phaseId}/${sessionId}`)}
                data-cy="application-button-next"
              >
                <FormattedMessage id="app.pages.application.production.buttons.check" defaultMessage="Check" />
              </Button>
            </div>
          </div>
        }
      >
        <ProductionBody />
      </Background>
    </Wrapper>
  );
}

Production.defaultProps = {};
Production.propTypes = {};
export default Production;
