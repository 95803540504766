import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import themes from '../../libs/themes';
import Background from '../../components/Layout';

function GenericError({ error }) {
  const history = useHistory();
  const theme = themes.default;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Error - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          foot={
            <div className="columns">
              <div className="column is-6">
                <Button isFullWidth theme={themes.primary.buttons} onClick={() => history.push('/')}>
                  <FormattedMessage id="app.shared.home" defaultMessage="Home" />
                </Button>
              </div>
              <div className="column is-6">
                <Button isFullWidth theme={themes.primary.buttons} onClick={() => window.location.reload()}>
                  <FormattedMessage id="app.shared.retry" defaultMessage="Retry" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <h2 className="title is-2">
                  <FormattedMessage id="app.shared.generic_error" defaultMessage="Sorry, error occurred" />
                </h2>
                {error && <p className="subtitle is-5 has-text-centered">{JSON.stringify(error)}</p>}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}
// TODO: Write proptypes
// Error.propTypes = {}
export default GenericError;
