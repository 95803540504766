import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Modal from '../Modal';
import PickingTable from './components/PickingTable';
import Button from '../Button';
import themes from '../../libs/themes';
import SendPhaseToSmiro from '../SendPhaseToSmiro';

const Container = styled.div`
  position: relative;
  height: 100%;

  .table-container {
    height: calc(100vh - 23rem);
    overflow-y: auto;
    display: block;
  }

  .actions {
    position: absolute;
    bottom: 2rem;
    width: 100%;
  }
`;

function PickingList({ phase, picking }) {
  const { phaseId } = useParams();
  const { features } = useSelector(({ config }) => config);
  if (picking.length === 0) {
    return <span />;
  }
  return (
    <Modal
      head={
        <div className="columns has-text-white">
          <div className="column">
            <p className="title is-4 w500 has-text-centered">
              {phase.sort} :: {phase.name} {phase.product && <small>- {phase.product.code}</small>}
            </p>
          </div>
        </div>
      }
      button={
        <>
          <img src={require(`../../images/doc/PICKING.svg`).default} alt="" />
          <p>
            <FormattedMessage id="app.components.docs.doc_default.doc.PICKING" defaultMessage="Picking list" />
          </p>
        </>
      }
    >
      <Container>
        <div className="table-container">
          <PickingTable picking={picking} />
        </div>
        {/* BUTTONS */}
        {features.includes('SMIRO:INTEGRATION') && (
          <div className="actions is-fullwidth">
            <div className="columns">
              <div className="column is-4 is-offset-8">
                <SendPhaseToSmiro>
                  {(onClick) => (
                    <Button isFullWidth theme={themes.primary.buttons} onClick={() => onClick(phaseId)}>
                      <FormattedMessage id="app.components.picking_list.SEND_PHASE" defaultMessage="Send to Smiro" />
                    </Button>
                  )}
                </SendPhaseToSmiro>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Modal>
  );
}

PickingList.defaultProps = {};
PickingList.propTypes = {
  phase: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
  }).isRequired,
  picking: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      qty: PropTypes.number,
      component: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        unit: PropTypes.string,
      }),
    })
  ).isRequired,
};
export default PickingList;
