import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../config';

function TimeFormatter({ active, seconds }) {
  function getHours(value) {
    const hours = Math.floor(value / 3600);
    if (hours < 10) {
      return `0${hours}`;
    }
    return hours || '00';
  }
  function getMinutes(value) {
    const minutes = Math.floor((value % 3600) / 60);
    if (minutes < 10) {
      return `0${minutes}`;
    }
    return minutes || '00';
  }
  function getSeconds(value) {
    const seconds = Math.floor(value % 60);
    if (seconds < 10) {
      return `0${seconds}`;
    }
    return seconds || '00';
  }
  return (
    <>
      {getHours(seconds)}
      <span className={classNames(active && 'blinking')}>:</span>
      {getMinutes(seconds)}
      {/* SECONDS */}
      {config.showSeconds && (
        <>
          <span className={classNames(active && 'blinking')}>:</span>
          {getSeconds(seconds)}
        </>
      )}
    </>
  );
}

TimeFormatter.defaultProps = {
  seconds: 0,
  active: false,
};
TimeFormatter.propTypes = {
  seconds: PropTypes.number,
  active: PropTypes.bool,
};
export default TimeFormatter;
