import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Title from '../../../../components/Title';
import { MediumButton } from '../../../../components/Button';
import OnScreenKeyboard from '../../../../components/OnScreenKeyboard';
import themes, { buttons } from '../../../../libs/themes';
import Background from '../../../../components/Layout';
import { SmallTag } from '../../../../components/Tag';
import Header from '../../../../components/Header';
import StepHeader from '../../../../components/StepHeader';

function SupplyItemBatch() {
  const inputEl = useRef(null);
  const history = useHistory();
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  const theme = themes.materialViolet;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Supply Item Batch - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={themes.materialViolet.buttons}>
                  <FormattedMessage id="app.shared.material" defaultMessage="Material" />
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.supply_item_batch.title"
                    defaultMessage="Insert or scan batch"
                  />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              <div className="column is-3">
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={() => history.goBack()}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage id="app.shared.back" defaultMessage="Back" />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      // onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage id="app.shared.ok" defaultMessage="Ok" />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column has-text-centered">
                <div className="separator" />
                <input
                  name="add-batch"
                  data-cy="application-button-batch-input"
                  id="add-batch"
                  type="text"
                  className="title is-1 is-double"
                  // defaultValue={picked || session.picked || 0}
                  onFocus={() => inputEl.current && inputEl.current.select()}
                  // autoFocus
                  ref={inputEl}
                  // onInput={(event) => dispatch(setPicked(+event.target.value))}
                  style={{
                    color: '#455a64',
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                />
              </div>
            </div>
            <OnScreenKeyboard inputNode={inputEl.current} />
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplyItemBatch.defaultProps = {};
SupplyItemBatch.propTypes = {};

export default SupplyItemBatch;
