import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-apollo';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Title from '../../../components/Title';
import Background from '../../../components/Layout';
import JOBS from './ChooseOrder.query.graphql';
import Button from '../../../components/Button';
import themes from '../../../libs/themes';
import Header from '../../../components/Header';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Job from './components/Job';
import BackImage from '../../../images/arrow-back-gray-icn.svg';
import { logout } from '../../../actions';
import OnScreenKeyboard, { KeyboardButton } from '../../../components/OnScreenKeyboard';

function ChooseOrder() {
  const intl = useIntl();
  const inputEl = useRef(null);
  const theme = themes.default;
  const [filter, setFilter] = useState('');
  const [showKeyboard, setShowKeyboard] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { stationId, hmiConfiguration } = useSelector(({ config }) => config);
  const { ON_SCREEN_KEYBOARD, PRODUCTION_ORDER_SORT: sort } = hmiConfiguration;

  const variables = { stationId, sort };
  const { data, loading, error } = useQuery(JOBS, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { jobs } = data;

  function filterer(job) {
    if (filter && filter.length === 0) {
      return true;
    }
    if (job.name.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (job.salesOrderId && job.salesOrderId.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (job.meta && job.meta.customerId && job.meta.customerId.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (job.product && job.product.code.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (job.customer && job.customer.name.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    return false;
  }

  const filtered = jobs.filter(filterer);

  function onBack() {
    dispatch(logout());
    history.push('/configuration/operator');
  }

  function onFocus() {
    if (!ON_SCREEN_KEYBOARD) {
      return;
    }
    setShowKeyboard(true);
    inputEl.current.select();
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Production orders - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth onClick={onBack}>
                  <img src={BackImage} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            {/* HEAD */}
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-1 w700">
                  <FormattedMessage
                    id="app.pages.configuration.orders.title"
                    defaultMessage="Choose order ({value, number} found)"
                    values={{ value: filtered.length }}
                  />
                </h1>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6 is-offset-3">
                <input
                  type="text"
                  className="input is-rounded title is-3 is-double"
                  placeholder={intl.formatMessage({
                    id: 'app.pages.configuration.orders.search.placeholder',
                    defaultMessage: 'Search order, product or customer...',
                  })}
                  value={filter}
                  onFocus={onFocus}
                  ref={inputEl}
                  onInput={(event) => setFilter(event.target.value)}
                />
              </div>
            </div>
            {/* DATA */}
            <div className="columns">
              <div className="column is-10 is-offset-1">
                {filtered.map((job) => (
                  <Job key={job._id} job={job} />
                ))}
                {filtered.length === 0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.configuration.orders.no_order"
                      defaultMessage="There is no job, contact responsible"
                    />
                  </Title.H2>
                )}
              </div>
            </div>
            {showKeyboard && (
              <OnScreenKeyboard
                inputNode={inputEl.current}
                leftButtons={[
                  <KeyboardButton
                    classes="close-button"
                    key={0}
                    onClick={() => setShowKeyboard(false)}
                    value={intl.formatMessage({
                      id: 'app.shared.close',
                      defaultMessage: 'Close',
                    })}
                  />,
                ]}
              />
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ChooseOrder.defaultProps = {};
ChooseOrder.propTypes = {};

export default ChooseOrder;
