import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Shows a loader
 * @constructor
 */
function LoadingIndicator() {
  return (
    <h2 className="title is-2">
      <FormattedMessage id="app.shared.loading" defaultMessage="Loading" />
    </h2>
  );
}

export default LoadingIndicator;
