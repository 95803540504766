import React from 'react';
import PropTypes from 'prop-types';
import NumberFormatter from '@agile-factory/component-number-formatter';

import Title from '../../../../components/Title';
import ListElement from '../../../../components/ListElement';
import themes from '../../../../libs/themes';

function Operation({ phase, onClick }) {
  return (
    <ListElement
      onClick={() => onClick(phase)}
      theme={themes.default.listElement}
      data-cy="configuration-operations-operation"
      data-cy-id={phase._id}
    >
      <div className="columns is-vcentered is-fullwidth is-marginless">
        <div className="column is-narrow">
          <Title.H3>{phase.sort}</Title.H3>
        </div>
        <div className="column truncate">
          <ul>
            {phase.product && <li className="title is-4 fg-secondary has-text-weight-normal">{phase.product.code}</li>}
            <li className="subtitle is-4 fg-secondary">{phase.name}</li>
          </ul>
        </div>
        <div className="column is-narrow">
          <h4 className="title is-4">
            <NumberFormatter value={phase.produced} />
            {' / '}
            <NumberFormatter value={phase.qty} />
          </h4>
        </div>
      </div>
    </ListElement>
  );
}

Operation.defaultProps = {};
Operation.propTypes = {
  onClick: PropTypes.func.isRequired,
  phase: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    _program: PropTypes.string.isRequired,
    _machine: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    produced: PropTypes.number,
    percentage: PropTypes.number,
    qty: PropTypes.number,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  }).isRequired,
};
export default Operation;
