import throttle from 'lodash.throttle';
import toast from './toast';
import intl from './format-message';

/**
 * Provides a singleton that manages the automatic logout for inactivity
 *
 * Referece: https://stackoverflow.com/questions/667555/how-to-detect-idle-time-in-javascript-elegantly
 */
class IdleTimeout {
  /**
   * Instantiate the singleton
   * @return {IdleTimeout|*}
   */
  constructor() {
    if (!IdleTimeout.instance) {
      this.timeout = null;
      this.callback = null;
      this.timer = null;
      this.events = ['scroll', 'mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
      IdleTimeout.instance = this;
    }
    return IdleTimeout.instance;
  }

  /**
   * Starts the idle timeout
   * @param timeout
   * @param callback
   */
  start = ({ timeout, callback = null }) => {
    console.log('Idle timeout starting', timeout);
    if (!timeout) {
      return;
    }
    console.log('Idle timeout started');
    this.timeout = timeout;
    this.callback = callback;
    this.events = ['scroll', 'mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    this.events.forEach((name) => {
      window.addEventListener(name, this.backToActive, true);
    });
    this.backToActive();
  };
  /**
   * Stops idle timeout
   */
  stop = () => {
    if (!this.timer) {
      throw new Error('Cannot stop because is not started');
    }
    console.log('Idle timeout stopped');
    clearTimeout(this.timer);
    this.events.forEach((name) => {
      window.removeEventListener(name, this.backToActive, true);
    });
  };

  /**
   * Fired on timeout
   */
  goIdle = () => {
    console.log('Idle timeout fired');
    toast({
      title: intl.formatMessage({
        id: 'app.libs.idle_timeout.timeout',
        defaultMessage: 'Timeout occurred, user logged out',
      }),
      icon: 'warning',
    });
    if (!!this.callback) {
      this.callback(this.timeout);
    }
  };
  /**
   * There was some activity, resets the timeout, the function has a throttle of 1 seconds
   * @type {debounced}
   */
  backToActive = throttle(() => {
    // console.log('Idle timeout reset');
    clearTimeout(this.timer);
    this.timer = setTimeout(this.goIdle, this.timeout);
  }, 1000);
}

const instance = new IdleTimeout();

export default instance;
