import React from 'react';

import CenteredLayout from '../layouts/CenteredLayout';
import LoadingIndicator from '../components/LoadingIndicator';
import ErrorIndicator from '../components/ErrorIndicator';
import Error from '../routes/errors/GenericError';

function withLoading(layout = true) {
  return function withLoading(WrappedComponent) {
    function WithLoading(props) {
      const { loading, error } = props;
      if (loading) {
        if (layout)
          return (
            <CenteredLayout>
              <LoadingIndicator {...props} />
            </CenteredLayout>
          );
        return <LoadingIndicator {...props} />;
      }
      if (error) {
        if (layout) return <Error error={error} {...props} />;
        return <ErrorIndicator error={error} {...props} />;
      }
      return <WrappedComponent {...props} />;
    }

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithLoading.displayName = `withLoading(${wrappedComponentName})`;
    return WithLoading;
  };
}

export default withLoading;
