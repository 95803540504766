import React from 'react';
import styled from 'styled-components';

const StyledBodyContainer = styled.div`
  align-items: stretch !important;
  //align-self: stretch;
  max-height: calc(100vh - 23.8rem);
  overflow: hidden;
  overflow-y: auto;
  padding: 2rem 1.5rem;
`;

function BodyContainer({ children, ...props }) {
  return (
    <StyledBodyContainer className="hero-body" {...props}>
      {children}
    </StyledBodyContainer>
  );
}

BodyContainer.defaultProps = {};
BodyContainer.propTypes = {};
export default BodyContainer;
