import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import Operators from './Operators';
import Avatar from '../../../../components/Avatar';
import { chunk } from '../../../../libs/helpers';

function OperatorsGrouped({ operators, onClick, pageSize }) {
  const { index } = useParams();
  const history = useHistory();
  if (operators.length < pageSize) {
    return <Operators onClick={onClick} operators={operators} pageSize={pageSize} />;
  }
  const count = Math.ceil(operators.length / pageSize);
  const result = chunk(operators, count);
  if (index) {
    return <Operators onClick={onClick} operators={result[index]} pageSize={pageSize} />;
  }
  return (
    <div className="columns">
      <div className="column">
        <div className="columns is-multiline">
          {result.map((operators, index) => (
            <div className="column is-3 has-text-centered" key={operators[0]._id}>
              <figure className="image" style={{ marginBottom: 8 }}>
                <a onClick={() => history.push(`/configuration/operator/${index}`)}>
                  <Avatar
                    size="12.8rem"
                    value={`${operators[0].firstName[0]} ... ${operators[operators.length - 1].firstName[0]}`}
                    textSizeRatio={4}
                  />
                </a>
              </figure>
              <h4 className="title is-4 w500 has-text-centered">{`${operators[0].firstName} - ${
                operators[operators.length - 1].firstName
              }`}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

OperatorsGrouped.defaultProps = {
  pageSize: 8,
};
OperatorsGrouped.propTypes = {
  pageSize: PropTypes.number,
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pin: PropTypes.string,
      role: PropTypes.string,
      image: PropTypes.string,
    })
  ),
};

export default OperatorsGrouped;
