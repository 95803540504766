import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useQuery } from 'react-apollo';

import Title from '../../../components/Title';
import Docs from '../../../components/Docs';
import Tag, { SmallTag } from '../../../components/Tag';
import Timer from '../../../components/Timer';
import Notes from '../../../components/Notes';
import Button from '../../../components/Button';
import themes from '../../../libs/themes';
import Background from '../../../components/Layout';
import SetStatus from '../../../components/SetStatus';
import Header from '../../../components/Header';
import StepHeader from '../../../components/StepHeader';
import PAUSE from './Pause.query.graphql';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../components/LoadingIndicator';

function Pause() {
  const { phaseId, sessionId, stepId } = useParams();
  const history = useHistory();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(PAUSE, { variables });
  const theme = themes.pauseAzure;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;

  return (
    <>
      <Helmet>
        <title>Pause - Agile Factory</title>
      </Helmet>
      <SetStatus status="PAUSE">
        <ThemeProvider theme={theme}>
          <Background
            head={<Header hasPhaseInfo />}
            foot={
              <div className="columns">
                <div className="column is-4 is-offset-4">
                  <Choose>
                    <When condition={phase.configuration.HMI_MODE === 'FREE'}>
                      <Button
                        isFullWidth
                        theme={themes.primary.buttons}
                        onClick={() => history.goBack()}
                        data-cy="application-button-back"
                      >
                        <FormattedMessage id="app.shared.resume" defaultMessage="resume" />
                      </Button>
                    </When>
                    <When condition={phase.configuration.HMI_MODE === 'WORKFLOW'}>
                      <Button
                        isFullWidth
                        theme={themes.primary.buttons}
                        onClick={() => history.push(`/application/step/${phaseId}/${sessionId}/${stepId}`)}
                        data-cy="application-button-back"
                      >
                        <FormattedMessage id="app.shared.resume" defaultMessage="resume" />
                      </Button>
                    </When>
                  </Choose>
                </div>
              </div>
            }
          >
            <>
              <div className="columns">
                <div className="column">
                  <SmallTag isUpperCase isLight theme={themes.pauseAzure.buttons}>
                    <FormattedMessage id="app.shared.pause" defaultMessage="Pause" />
                  </SmallTag>
                  <Title.H1>
                    {phase.sort} :: {phase.name}
                  </Title.H1>
                  <StepHeader />
                </div>
                <div className="column is-narrow">
                  <Tag theme={themes.pauseAzure.buttons}>
                    <i className="icon-giphy-time" />
                    <Timer time={session.timers.PAUSE || 0} active />
                    <small style={{ color: 'rgba(255,255,255,.85)' }}>
                      {' / '}
                      <Timer time={session.timers.TOTAL || 0} active />
                    </small>
                  </Tag>
                </div>
              </div>
              <div className="columns">
                <div className="column is-half has-text-left">
                  <Docs filterFn={(doc) => ['BLUEPRINT', 'PRODUCTION'].includes(doc.type)} />
                </div>
                <div className="column is-half has-text-left">
                  <Notes />
                </div>
              </div>
            </>
          </Background>
        </ThemeProvider>
      </SetStatus>
    </>
  );
}
Pause.propTypes = {};

export default Pause;
