import React from 'react';
import PropTypes from 'prop-types';
import Keyboard, { KeyboardButton, LatinLayout, NumericLayout } from 'react-screen-keyboard';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import themes from '../libs/themes';

const StyledWrapper = styled.div`
  .close-button {
    background-color: ${themes.errorRed.border};
    color: ${themes.errorRed.bg};
  }
`;

function OnScreenKeyboard({ inputNode, layouts = [LatinLayout], leftButtons, rightButtons }) {
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { ON_SCREEN_KEYBOARD } = hmiConfiguration;
  if (!ON_SCREEN_KEYBOARD) {
    return <span />;
  }

  return (
    <StyledWrapper className="keyboard-container">
      {inputNode && (
        <Keyboard inputNode={inputNode} layouts={layouts} leftButtons={leftButtons} rightButtons={rightButtons} />
      )}
    </StyledWrapper>
  );
}

OnScreenKeyboard.defaultProps = {
  inputNode: null,
};
OnScreenKeyboard.propTypes = {
  inputNode: PropTypes.object,
  layouts: PropTypes.arrayOf(PropTypes.any),
  rightButtons: PropTypes.arrayOf(PropTypes.any),
  leftButtons: PropTypes.arrayOf(PropTypes.any),
};
export { KeyboardButton, LatinLayout, NumericLayout };
export default OnScreenKeyboard;
