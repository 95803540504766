import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Background from '../../../../components/Layout/Background';
import themes from '../../../../libs/themes';
import Header from '../../../../components/Header';
import toast from '../../../../libs/toast';
import onError from '../../../../libs/error-logger';
import CONTAINER_BATCH from './ContainerBatch.query.graphql';
import CONTAINER_BATCH_MUTATION from './ContainerBatch.mutation.graphql';
import { setLoading } from '../../../../actions';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import GenericError from '../../../errors/GenericError';
import ContainerBatchStep1 from './components/ContainerBatchStep1';
import ContainerBatchStep2 from './components/ContainerBatchStep2';

function ContainerBatch() {
  const intl = useIntl();
  const inputEl = useRef(null);
  const [step, setStep] = useState(1);
  const [batch, setBatch] = useState();
  const [notes, setNotes] = useState();
  const { containerId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  const variables = { containerId };

  const { loading, error, data } = useQuery(CONTAINER_BATCH, { variables });
  const [mutate] = useMutation(CONTAINER_BATCH_MUTATION, { variables });

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      await mutate({ variables: { batch, notes } });
      toast({ title: intl.formatMessage({ id: 'app.shared.save_success' }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { container } = data;

  const theme = themes.default;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Container Batch - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasSessionInfo={false} hasUserInfo />}>
          {step === 1 && (
            <ContainerBatchStep1 container={container} setBatch={setBatch} batch={batch} setStep={setStep} />
          )}
          {step === 2 && (
            <ContainerBatchStep2
              container={container}
              setNotes={setNotes}
              notes={notes}
              setStep={setStep}
              onSubmit={onSubmit}
            />
          )}
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerBatch.defaultProps = {};
ContainerBatch.propTypes = {};

export default ContainerBatch;
