import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';

import Background from '../../../../components/Layout/Background';
import themes from '../../../../libs/themes';
import Header from '../../../../components/Header';
import CONTAINERS from './Containers.query.graphql';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';
import Subtitle from '../../../../components/Subtitle';
import ContainerList from './components/ContainerList';

function Containers() {
  const { phaseId, sessionId } = useParams();
  const history = useHistory();
  const { stationId } = useSelector(({ config }) => config);
  const variables = { phaseId, sessionId, stationId };
  const { data, loading, error } = useQuery(CONTAINERS, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { containers } = data;

  const theme = themes.neutralGrey;
  return (
    <>
      <Helmet>
        <title>Containers - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasSessionInfo={false} hasUserInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img src={require('../../../../images/arrow-back-gray-icn.svg').default} alt="Back" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage id="app.pages.application.containers.title" defaultMessage="Containers" />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.application.containers.subtitle"
                    defaultMessage="Configure container"
                  />
                </Subtitle.S3>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <ContainerList
                  containers={containers}
                  onClick={(containerId) => history.push(`/application/container-content/${containerId}`)}
                />
              </div>
              {containers.length === 0 && (
                <div className="column">
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.application.containers.empty"
                      defaultMessage="Containers not configured, please contact a manager"
                    />
                  </Title.H2>
                </div>
              )}
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Containers.defaultProps = {};
Containers.propTypes = {};
export default Containers;
