import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Background from '../../../components/Layout/Background';
import Button from '../../../components/Button';
import MaterialBody from '../components/material/MaterialBody';
import themes from '../../../libs/themes';
import Header from '../../../components/Header';
import Wrapper from './components/Wrapper';

function Material() {
  const history = useHistory();
  const { phaseId, sessionId } = useParams();
  return (
    <Wrapper status="MATERIAL">
      <Background
        head={<Header hasMenu />}
        foot={
          <div className="columns">
            {/* FIRST BUTTON */}
            <div className="column">
              {/* MENU BUTTON */}
              <Button
                isFullWidth
                onClick={() => history.push(`/application/menu/${phaseId}/${sessionId}`)}
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.menu" defaultMessage="Menu" />
              </Button>
            </div>
            {/* SECOND BUTTON */}
            <div className="column">
              <Button
                isFullWidth
                theme={themes.materialViolet.buttons}
                onClick={() => history.push(`/application/picked/${phaseId}/${sessionId}`)}
                data-cy="application-button-picked"
              >
                <FormattedMessage id="app.pages.application.material.buttons.picked" defaultMessage="Picked" />
              </Button>
            </div>
            {/* THIRD BUTTON */}
            <div className="column">
              <Button
                isFullWidth
                theme={themes.setupYellow.buttons}
                onClick={() => history.push(`/application/setup/${phaseId}/${sessionId}`)}
                data-cy="application-button-next"
              >
                <FormattedMessage id="app.pages.application.material.buttons.setup" defaultMessage="Setup" />
              </Button>
            </div>
          </div>
        }
      >
        <MaterialBody />
      </Background>
    </Wrapper>
  );
}

Material.defaultProps = {};
Material.propTypes = {};
export default Material;
