import en from './english.json';
import it from './italian.json';
import ru from './russian.json';
import next from './customers/it-next';

const customer = process.env.REACT_APP_CUSTOMER;
export default function () {
  if (customer === 'it-next') {
    return {
      en: { ...en, ...next.en },
      it: { ...it, ...next.it },
      ru: { ...ru, ...next.ru },
    };
  }
  return {
    en,
    it,
    ru,
  };
}
