import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ThemeProvider } from 'styled-components';

import { withPhase } from '../../../hocs';
import { setScrap } from '../../../actions';
import { phasePropTypes } from '../../../libs/proptypes';
import { MediumButton } from '../../../components/Button';
import toast from '../../../libs/toast';
import OnScreenKeyboard from '../../../components/OnScreenKeyboard';
import Title from '../../../components/Title';
import Background from '../../../components/Layout';
import themes, { buttons } from '../../../libs/themes';
import { SmallTag } from '../../../components/Tag';
import Header from '../../../components/Header';
import StepHeader from '../../../components/StepHeader';

class Scrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      inputNode: null,
      scrap: this.props.scrap || false,
    };
  }

  static defaultProps = {
    loading: true,
  };

  static propTypes = {
    phase: phasePropTypes,
    loading: PropTypes.bool,
  };

  componentDidMount = () => {
    this.handleFocus();
  };

  handleFocus = () => {
    this.input && this.input.select();
    this.setState({ inputNode: this.input });
  };

  onChange = (event) => {
    this.setState({ scrap: event.target.value });
    console.log(this.state.scrap);
  };

  onSubmit = () => {
    const {
      dispatch,
      match: {
        params: { phaseId, sessionId },
      },
      history,
      produced,
      phase: {
        configuration: { SUBTRACT_SCRAP },
      },
    } = this.props;
    const { scrap } = this.state;
    if (isNaN(scrap)) {
      this.showError('app.shared.nan');
      return;
    }
    if (scrap > produced && SUBTRACT_SCRAP) {
      this.showError('app.pages.application.scrap.error.scrap_greater_than_produced');
      return;
    }
    dispatch(setScrap(scrap || 0));
    history.push(`/application/notes/${phaseId}/${sessionId}`);
  };

  onBack = () => {
    const { dispatch, history } = this.props;
    dispatch(setScrap());
    history.goBack();
  };

  showError = (message) => {
    const { intl } = this.props;
    this.setState({ isError: true });
    toast({ title: intl.formatMessage({ id: message }), icon: 'error' });
    setTimeout(() => this.setState({ isError: false }), 800);
  };

  render() {
    const { status } = this.props;
    const { inputNode, scrap, isError } = this.state;
    const theme = themes.default;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Scrap - Agile Factory</title>
        </Helmet>
        <ThemeProvider theme={theme}>
          <Background head={<Header hasPhaseInfo />}>
            <>
              <div className="columns">
                <div className="column">
                  <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                    <Choose>
                      <When condition={status === 'INTERRUPT'}>
                        <FormattedMessage id="app.shared.delivery.partial" defaultMessage="Partial delivery" />
                      </When>
                      <When condition={status === 'DONE'}>
                        <FormattedMessage id="app.shared.delivery.final" defaultMessage="Final delivery" />
                      </When>
                      <Otherwise>
                        <FormattedMessage id="app.shared.delivery" defaultMessage="Delivery" />
                      </Otherwise>
                    </Choose>
                  </SmallTag>
                  <Title.H1>
                    <FormattedMessage id="app.pages.application.scrap.title" defaultMessage="Insert scrap" />
                  </Title.H1>
                  <StepHeader type="INPUT" />
                </div>
                <div className="column is-3">
                  <div className="columns">
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.default}
                        onClick={this.onBack}
                        data-cy="application-button-back"
                      >
                        <FormattedMessage id="app.shared.back" defaultMessage="Back" />
                      </MediumButton>
                    </div>
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.productionBlue}
                        onClick={this.onSubmit}
                        data-cy="application-button-ok"
                      >
                        <FormattedMessage id="app.shared.ok" defaultMessage="Ok" />
                      </MediumButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className={classNames('column has-text-centered', isError && 'animated shake duration400')}>
                  <div className="separator" />
                  <input
                    name="add-scrap"
                    id="add-scrap"
                    data-cy="application-scrap-input"
                    type="text"
                    className="title is-1 is-double"
                    placeholder="0"
                    defaultValue={scrap || 0}
                    step="any"
                    min="0"
                    onFocus={this.handleFocus}
                    autoFocus
                    ref={(input) => {
                      this.input = input;
                    }}
                    onInput={this.onChange}
                    style={{
                      color: '#455a64',
                      width: '100%',
                      border: 'none',
                      outline: 'none',
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                    }}
                  />
                </div>
              </div>
              <OnScreenKeyboard inputNode={inputNode} layouts={[require('react-screen-keyboard').NumericLayout]} />
            </>
          </Background>
        </ThemeProvider>
      </>
    );
  }
}

export default compose(
  injectIntl,
  connect(({ delivery }) => ({
    status: delivery.status,
    produced: delivery.session.produced,
    scrap: delivery.session.scrap,
  })),
  withPhase()
)(Scrap);
