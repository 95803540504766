import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';

import Docs from '../../../../components/Docs';
import Notes from '../../../../components/Notes';
import Tag, { SmallTag } from '../../../../components/Tag';
import Timer from '../../../../components/Timer';
import themes from '../../../../libs/themes';
import Title from '../../../../components/Title';
import StepHeader from '../../../../components/StepHeader';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import SETUP from './SetupBody.query.graphql';

/**
 * Setup page
 *
 * @param stepName
 * @param phase
 * @constructor
 */
function SetupBody({ stepName }) {
  const { phaseId, sessionId } = useParams();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(SETUP, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;
  return (
    <>
      <Helmet>
        <title>Setup - Agile Factory</title>
      </Helmet>
      <div className="columns">
        <div className="column">
          <SmallTag isUpperCase isLight theme={themes.setupYellow.buttons}>
            {stepName || <FormattedMessage id="app.shared.setup" defaultMessage="Setup" />}
          </SmallTag>
          <Title.H1>
            {phase.sort} :: {phase.name}
          </Title.H1>
          <StepHeader />
        </div>
        <div className="column is-narrow">
          <Tag theme={themes.setupYellow.buttons}>
            <i className="icon-giphy-time" />
            <Timer time={session.timers.SETUP || 0} active />
            <small style={{ color: 'rgba(255,255,255,.85)' }}>
              {' / '}
              <Timer time={session.timers.TOTAL || 0} active />
            </small>
          </Tag>
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-left">
          <Docs filterFn={(doc) => doc.type === 'SETUP'} />
        </div>
        <div className="column has-text-left">
          <Notes />
        </div>
      </div>
    </>
  );
}

SetupBody.defaultProps = {
  stepName: 'SETUP',
};
SetupBody.propTypes = {
  stepName: PropTypes.string,
};

export default SetupBody;
