import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSubscription } from 'react-apollo';

import { statuses } from '../../../../libs/themes';
import CYCLE_RECEIVED from './Wrapper.subscription.graphql';
import SetStatus from '../../../../components/SetStatus';

function Wrapper({ status, children }) {
  const { phaseId } = useParams();

  const variables = { phaseId };
  useSubscription(CYCLE_RECEIVED, { variables });

  const theme = statuses[status || 'INTERRUPT'];
  return (
    <ThemeProvider theme={theme}>
      <SetStatus status={status}>{children}</SetStatus>
    </ThemeProvider>
  );
}

Wrapper.defaultProps = {};
Wrapper.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};
export default Wrapper;
