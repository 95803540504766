import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { NumericLayout } from 'react-screen-keyboard';

import OnScreenKeyboard from '../../../components/OnScreenKeyboard';
import themes, { buttons } from '../../../libs/themes';
import Background from '../../../components/Layout/Background';
import Header from '../../../components/Header';
import { SmallTag } from '../../../components/Tag';
import Title from '../../../components/Title';
import StepHeader from '../../../components/StepHeader';
import { MediumButton } from '../../../components/Button';
import PRODUCED_QUERY from './Produced.query.graphql';
import toast from '../../../libs/toast';
import { resetPhase, setProduced } from '../../../actions';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../components/LoadingIndicator';
import GenericError from '../../errors/GenericError';
import confirmAction from '../../../libs/confirm_action';

function Produced({ intl }) {
  const inputEl = useRef(null);
  const [isError, setIsError] = useState(false);
  const { phaseId, sessionId } = useParams();
  const [currentSession] = useState(sessionId);

  const delivery = useSelector(({ delivery }) => delivery);

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  const variables = { sessionId: currentSession };

  const { loading, error, data } = useQuery(PRODUCED_QUERY, { variables });

  const theme = themes.default;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { session } = data;
  const { phase } = session;

  function showError(message) {
    setIsError(true);
    toast({ title: intl.formatMessage({ id: message }), icon: 'error' });
    setTimeout(() => setIsError(false), 800);
  }

  function onBack() {
    if (delivery.status === 'ERROR') {
      dispatch(setProduced());
    } else {
      dispatch(resetPhase());
    }
    return history.goBack();
  }

  async function onSubmit() {
    const { DELIVER_MORE_THAN_QTY } = phase.configuration;
    const produced = delivery.session.produced || session.produced || 0;
    if (isNaN(produced)) {
      showError('app.shared.nan');
      return;
    }
    const total = +phase.produced - +(session.produced || 0) + +produced;
    // If HMI DELIVER_MORE_THAN_QTY is true and delivering more than QTY block user
    if (DELIVER_MORE_THAN_QTY === false && total > +phase.qty && +phase.qty > 0) {
      return toast({
        title: intl.formatMessage(
          {
            id: 'app.pages.application.produced.error.greater_than_qty',
            defaultMessage:
              'Attention, total production is greater than quantity. Production: {total, number}/{qty, number}',
          },
          { total, qty: phase.qty }
        ),
        icon: 'error',
      });
    }
    // If operation has dependencies, check if they have DEPENDENCIES_DELIVER_MORE_THAN_QTY false
    if (phase.dependencies.length > 0) {
      const check = phase.dependencies.filter(
        (p) => p.phase.configuration.DEPENDENCIES_DELIVER_MORE_THAN_QTY === false
      );
      const [min] = check.map((d) => d.phase.produced).sort();
      // debugger;
      if (min && total > min) {
        return toast({
          title: intl.formatMessage(
            {
              id: 'app.pages.application.produced.error.greater_than_dependencies',
              defaultMessage:
                'Attention, total production is greater than previous delivered quantity. Production: {total, number}/{min, number}',
            },
            { total, min }
          ),
          icon: 'error',
        });
      }
    }
    // If final delivery and TOTAL < QTY and QTY > 0 displays a warning
    if (delivery.status === 'DONE' && total < +phase.qty && +phase.qty > 0) {
      return confirmAction({
        confirm() {
          dispatch(setProduced(produced));
          history.push(`/application/scrap/${phaseId}/${sessionId}`);
        },
        options: {
          text: intl.formatMessage(
            {
              id: 'app.pages.application.produced.confirm.less_than_qty',
              defaultMessage:
                'Attention, total production is less than quantity. Production: {total, number}/{qty, number}',
            },
            { total, qty: phase.qty }
          ),
        },
      });
    }
    // If partial delivery and TOTAL > QTY and QTY > 0 displays a warning
    if (delivery.status === 'INTERRUPT' && total > +phase.qty && +phase.qty > 0) {
      return confirmAction({
        confirm() {
          dispatch(setProduced(produced));
          history.push(`/application/scrap/${phaseId}/${sessionId}`);
        },
        options: {
          text: intl.formatMessage(
            {
              id: 'app.pages.application.produced.confirm.greater_than_qty',
              defaultMessage:
                'Attention, total production is greater than quantity. Production: {total, number}/{qty, number}',
            },
            { total, qty: phase.qty }
          ),
        },
      });
    }
    dispatch(setProduced(produced));
    history.push(`/application/scrap/${phaseId}/${sessionId}`);
  }

  const { PRODUCTIVITY_INPUT_ENABLED } = phase.configuration;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Produced - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <Choose>
                    <When condition={delivery.status === 'INTERRUPT'}>
                      <FormattedMessage id="app.shared.delivery.partial" defaultMessage="Partial delivery" />
                    </When>
                    <When condition={delivery.status === 'DONE'}>
                      <FormattedMessage id="app.shared.delivery.final" defaultMessage="Final delivery" />
                    </When>
                    <Otherwise>
                      <FormattedMessage id="app.shared.delivery" defaultMessage="Delivery" />
                    </Otherwise>
                  </Choose>
                </SmallTag>
                <Title.H1>
                  <FormattedMessage id="app.pages.application.produced.title" defaultMessage="How many pieces?" />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              <div className={classNames('column', PRODUCTIVITY_INPUT_ENABLED ? 'is-4' : 'is-3')}>
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={onBack}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage id="app.shared.back" defaultMessage="Back" />
                    </MediumButton>
                  </div>
                  {/* {(session._children.length > 0 || session._parent) && ( */}
                  {/*  <div className="column"> */}
                  {/*    <MediumButton */}
                  {/*      isFullWidth */}
                  {/*      theme={buttons.default} */}
                  {/*      onClick={() => setShowChooseSession(!showChooseSession)} */}
                  {/*      data-cy="application-button-change-session" */}
                  {/*    > */}
                  {/*      <FormattedMessage id="app.shared.change_session" defaultMessage="Session" /> */}
                  {/*    </MediumButton> */}
                  {/*  </div> */}
                  {/* )} */}
                  {PRODUCTIVITY_INPUT_ENABLED && (
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.blueyGrey}
                        onClick={() => {
                          dispatch(setProduced(+phase.missing));
                          inputEl.current.value = `${phase.missing}`;
                        }}
                        data-cy="application-button-missing"
                      >
                        <FormattedMessage
                          id="app.pages.application.produced.missing_qty"
                          defaultMessage="{value, number} {value, plural, one {piece} other {pieces}}"
                          values={{ value: phase.missing }}
                        />
                      </MediumButton>
                    </div>
                  )}
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage id="app.shared.ok" defaultMessage="Ok" />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className={classNames('column has-text-centered', isError && 'animated shake duration400')}>
                <div className="separator" />
                <input
                  name="add-produced"
                  id="add-produced"
                  type="text"
                  step="any"
                  min="0"
                  className="title is-1 is-double"
                  placeholder="0"
                  defaultValue={delivery.session.produced || session.produced || 0}
                  onFocus={() => inputEl.current && inputEl.current.select()}
                  autoFocus
                  ref={inputEl}
                  onInput={(event) => dispatch(setProduced(+event.target.value))}
                  disabled={!PRODUCTIVITY_INPUT_ENABLED}
                  style={{
                    color: '#455a64',
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                />
              </div>
            </div>
            {PRODUCTIVITY_INPUT_ENABLED && <OnScreenKeyboard inputNode={inputEl.current} layouts={[NumericLayout]} />}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Produced.defaultProps = {};
Produced.propTypes = {};

export default injectIntl(Produced);
