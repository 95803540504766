import { capture } from '@agile-factory/module-error-tracking-browser';
import intl from './format-message';
import toast from './toast';

/**
 * Shows an error message
 * @param err
 */
// TODO: log somewhere outside...
function onError(err) {
  capture(err);
  toast({
    icon: 'error',
    title: intl.formatMessage({ id: 'app.shared.there_was_an_error', defaultMessage: 'There was an error' }),
  });
}

export default onError;
