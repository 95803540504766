import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { withPhase } from '../../../hocs';
import { setStatus, setError } from '../../../actions';
import { phasePropTypes } from '../../../libs/proptypes';
import themes, { buttons } from '../../../libs/themes';
import Background from '../../../components/Layout';
import Title from '../../../components/Title';
import { MediumButton } from '../../../components/Button';
import OnScreenKeyboard from '../../../components/OnScreenKeyboard';
import { SmallTag } from '../../../components/Tag';
import Header from '../../../components/Header';
import StepHeader from '../../../components/StepHeader';

class ErrorReport extends React.Component {
  static defaultProps = {
    loading: true,
    message: '',
  };

  static propTypes = {
    phase: phasePropTypes,
    loading: PropTypes.bool,
    reportIssue: PropTypes.func,
    setStatus: PropTypes.func,
  };

  state = {
    inputNode: null,
    message: this.props.message || '',
    isError: false,
  };

  componentDidMount = () => {
    this.handleFocus();
  };

  handleFocus = () => {
    this.setState({ inputNode: this.input });
  };

  onSubmit = () => {
    const {
      dispatch,
      match: {
        params: { phaseId, sessionId },
      },
      history,
    } = this.props;
    const { message } = this.state;
    if (!message || message.length === 0) {
      this.setState({ isError: true });
      setTimeout(() => this.setState({ isError: false }), 800);
      return;
    }
    dispatch(setError(message));
    dispatch(setStatus('ERROR'));
    history.push(`/application/produced/${phaseId}/${sessionId}`);
  };

  onChange = (event) => {
    this.setState({ message: event.target.value });
    console.log(this.state.message);
  };

  render() {
    const { message, intl, history } = this.props;
    const { inputNode, isError } = this.state;
    const theme = themes.errorRed;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Problem Report - Agile Factory</title>
        </Helmet>
        <ThemeProvider theme={theme}>
          <Background head={<Header hasPhaseInfo />}>
            <>
              <div className="columns">
                <div className="column">
                  <SmallTag isUpperCase isLight theme={themes.errorRed.buttons}>
                    <FormattedMessage id="app.shared.error" defaultMessage="Fault" />
                  </SmallTag>
                  <Title.H1>
                    <FormattedMessage id="app.pages.application.error_report.title" defaultMessage="Report fault" />
                  </Title.H1>
                  <StepHeader type="INPUT" />
                </div>
                <div className="column is-3">
                  <div className="columns">
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.default}
                        onClick={() => history.goBack()}
                        data-cy="application-button-back"
                      >
                        <FormattedMessage id="app.shared.back" defaultMessage="Back" />
                      </MediumButton>
                    </div>
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.productionBlue}
                        onClick={this.onSubmit}
                        data-cy="application-button-ok"
                      >
                        <FormattedMessage id="app.shared.ok" defaultMessage="Ok" />
                      </MediumButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className={classNames('column has-text-centered', isError && 'animated shake duration400')}>
                  <div className="separator" />
                  <textarea
                    onFocus={this.handleFocus}
                    data-cy="application-issue-input"
                    ref={(input) => {
                      this.input = input;
                    }}
                    name="problem-issue"
                    id="problem-issue"
                    rows="6"
                    autoFocus
                    className={classNames('title is-4', isError && 'validation-error')}
                    onInput={this.onChange}
                    defaultValue={message || ''}
                    placeholder={intl.formatMessage({
                      id: 'app.pages.application.error_report.placeholder',
                    })}
                  />
                </div>
              </div>
              <OnScreenKeyboard inputNode={inputNode} />
            </>
          </Background>
        </ThemeProvider>
      </>
    );
  }
}

export default compose(
  injectIntl,
  withPhase(),
  connect(({ delivery }) => ({ message: delivery.error }))
)(ErrorReport);
