import { RESET_PHASE, SET_PRODUCED, SET_NOTES, SET_SCRAP, SET_ERROR, SET_STATUS } from '../actions';

const INITIAL_STATE = {
  status: null,
  error: null,
  session: {
    produced: null,
    notes: null,
    scrap: null,
  },
  operations: new Map(),
};
/**
 * Delivery Reducer
 * Used for storing informations about Delivery
 *
 * @param state
 * @param action
 * @returns {*}
 */
const reducer = (state = INITIAL_STATE, { type, value }) => {
  switch (type) {
    case RESET_PHASE:
      return INITIAL_STATE;
    case SET_ERROR:
      return { ...state, error: value };
    case SET_STATUS:
      return { ...state, status: value };
    case SET_PRODUCED:
      if (value) {
        return { ...state, session: { ...state.session, produced: +value } };
      }
      return { ...state, session: { ...state.session, produced: null } };
    case SET_SCRAP:
      if (value) {
        return { ...state, session: { ...state.session, scrap: +value } };
      }
      return { ...state, session: { ...state.session, scrap: null } };
    case SET_NOTES:
      return { ...state, session: { ...state.session, notes: value } };
    default:
      return state;
  }
};

export default reducer;
