import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { NumericLayout } from 'react-screen-keyboard';

import Title from '../../../components/Title';
import { MediumButton } from '../../../components/Button';
import toast from '../../../libs/toast';
import OnScreenKeyboard from '../../../components/OnScreenKeyboard';
import onError from '../../../libs/error-logger';
import PICKED_QUERY from './Picked.query.graphql';
import PICKED_MUTATION from './Picked.mutation.graphql';
import themes, { buttons } from '../../../libs/themes';
import Background from '../../../components/Layout';
import { SmallTag } from '../../../components/Tag';
import Header from '../../../components/Header';
import StepHeader from '../../../components/StepHeader';
import { setLoading } from '../../../actions';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../components/LoadingIndicator';
import GenericError from '../../errors/GenericError';

function Picked() {
  const intl = useIntl();
  const inputEl = useRef(null);
  const [isError, setIsError] = useState(false);
  const [picked, setPicked] = useState();
  const { sessionId } = useParams();
  const [currentSession] = useState(sessionId);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  const variables = { sessionId: currentSession };

  const { loading, error, data } = useQuery(PICKED_QUERY, { variables });
  const [mutate] = useMutation(PICKED_MUTATION, { variables });
  const theme = themes.materialViolet;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  function showError(message) {
    setIsError(true);
    toast({ title: intl.formatMessage({ id: message }), icon: 'error' });
    setTimeout(() => setIsError(false), 800);
  }
  async function onSubmit() {
    if (!picked) {
      return;
    }
    if (isNaN(picked)) {
      return showError('app.shared.nan');
    }
    dispatch(setLoading(true));
    try {
      await mutate({ variables: { picked: Number(picked) } });
      toast({ title: intl.formatMessage({ id: 'app.shared.save_success' }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  const { session } = data;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Picked - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={themes.materialViolet.buttons}>
                  <FormattedMessage id="app.shared.material" defaultMessage="Material" />
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.picked.title"
                    defaultMessage="How much material picked?"
                  />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              <div className="column is-4">
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={() => history.goBack()}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage id="app.shared.back" defaultMessage="Back" />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.blueyGrey}
                      onClick={() => {
                        setPicked(session.phase.missing);
                        inputEl.current.value = `${session.phase.missing}`;
                      }}
                      data-cy="application-button-missing"
                    >
                      <FormattedMessage
                        id="app.pages.application.picked.missing_qty"
                        defaultMessage="{value, number} {value, plural, one {piece} other {pieces}}"
                        values={{ value: session.phase.missing }}
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                      disabled={isNaN(picked)}
                    >
                      <FormattedMessage id="app.shared.ok" defaultMessage="Ok" />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className={classNames('column has-text-centered', isError && 'animated shake duration400')}>
                <div className="separator" />
                <input
                  name="add-picked"
                  data-cy="application-button-picked-input"
                  id="add-picked"
                  type="text"
                  step="any"
                  min="0"
                  className="title is-1 is-double"
                  placeholder="0"
                  defaultValue={picked || session.picked || 0}
                  onFocus={() => inputEl.current && inputEl.current.select()}
                  autoFocus
                  ref={inputEl}
                  onInput={(event) => setPicked(event.target.value)}
                  style={{
                    color: '#455a64',
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                />
              </div>
            </div>
            <OnScreenKeyboard inputNode={inputEl.current} layouts={[NumericLayout]} />
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Picked.defaultProps = {};
Picked.propTypes = {};

export default Picked;
