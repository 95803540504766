import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import OnScreenKeyboard, { KeyboardButton, NumericLayout } from '../../../../../components/OnScreenKeyboard';

function AlertThreshold({ qty, setQty }) {
  const intl = useIntl();

  const inputEl = useRef(null);
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { ON_SCREEN_KEYBOARD } = hmiConfiguration;

  function onFocus() {
    if (!ON_SCREEN_KEYBOARD) {
      return;
    }
    setShowKeyboard(true);
    inputEl.current.select();
  }
  return (
    <>
      <div className="columns">
        <div className="column has-text-centered">
          <div className="separator" />
          <input
            type="text"
            data-cy="application-supply_settings-qty-input"
            id="qty"
            min="0"
            className="title is-1 is-double"
            placeholder={0}
            value={qty}
            onFocus={onFocus}
            ref={inputEl}
            onInput={(event) => setQty(+event.target.value)}
            style={{
              color: '#455a64',
              width: '100%',
              border: 'none',
              outline: 'none',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          />
        </div>
      </div>
      {showKeyboard && (
        <OnScreenKeyboard
          inputNode={inputEl.current}
          layouts={[NumericLayout]}
          leftButtons={[
            <KeyboardButton
              classes="close-button"
              key={0}
              onClick={() => setShowKeyboard(false)}
              value={intl.formatMessage({
                id: 'app.shared.close',
                defaultMessage: 'Close',
              })}
            />,
          ]}
        />
      )}
    </>
  );
}

AlertThreshold.defaultProps = {
  qty: null,
};
AlertThreshold.propTypes = {
  qty: PropTypes.number,
  setQty: PropTypes.func.isRequired,
};
export default AlertThreshold;
