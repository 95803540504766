import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Sessions from '../Sessions/Sessions';
import Version from '../Version';
import { FullHeightContainer, HeadContainer, BodyContainer, FootContainer } from '.';
import { connect } from 'react-redux';
import { compose } from 'redux';

const StyledBackground = styled.div`
  color: ${({ theme }) => theme.fg};
  background: ${({ theme }) => theme.border};
  padding: 2rem 2rem 2rem 8rem;
  .loading-overlay {
    z-index: 10000000;
    display: ${({ isLoading }) => (isLoading === true ? 'block' : 'none')};
    //display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    cursor: not-allowed;
    background: black;
    opacity: 0.2;
  }
`;

/**
 * Background
 *
 * @param loading
 * @param head
 * @param children
 * @param foot
 * @param headHasBorderBottom
 * @returns {*}
 * @constructor
 */
function Background({ isLoading, head, children, foot, hasSessions, headHasBorderBottom }) {
  // console.log({ isLoading })
  return (
    <StyledBackground isLoading={isLoading}>
      <Sessions isDisabled={!hasSessions} />
      <FullHeightContainer>
        <>
          <HeadContainer hasBorderBottom={headHasBorderBottom}>{head}</HeadContainer>
          <BodyContainer>{children}</BodyContainer>
          <FootContainer>{foot}</FootContainer>
        </>
      </FullHeightContainer>
      <Version />
      <div className="loading-overlay" />
    </StyledBackground>
  );
}

Background.defaultProps = {
  isLoading: false,
  hasSessions: true,
  headHasBorderBottom: true,
  headhasPhasePause: true,
};
Background.propTypes = {
  isLoading: PropTypes.bool,
  head: PropTypes.element,
  children: PropTypes.element,
  foot: PropTypes.element,
  headHasBorderBottom: PropTypes.bool,
};

function mapStateToProps({ application }) {
  const { isLoading } = application;
  return { isLoading };
}
const withLoading = connect(mapStateToProps);

export default compose(withLoading)(Background);
