import { SET_USER, LOGOUT } from '../actions';

const INITIAL_STATE = {
  user: { worker: null, token: null },
};

/**
 * User reducer
 * @param state
 * @param action
 * @returns {*}
 */
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return INITIAL_STATE;
    case SET_USER:
      return { ...state, ...action.user };
    default:
      return state;
  }
};

export default reducer;
