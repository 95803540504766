import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useQuery } from 'react-apollo';

import Title from '../../../components/Title';
import Docs from '../../../components/Docs';
import { SmallTag } from '../../../components/Tag';
import Notes from '../../../components/Notes';
import Button from '../../../components/Button';
import themes, { buttons } from '../../../libs/themes';
import Background from '../../../components/Layout';
import Header from '../../../components/Header';
import StepHeader from '../../../components/StepHeader';
import INFO from './Info.query.graphql';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../components/LoadingIndicator';

function Info() {
  const history = useHistory();

  const { phaseId } = useParams();
  const variables = { phaseId };
  const { data, loading, error } = useQuery(INFO, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase } = data;

  const theme = themes.neutralGrey;
  return (
    <>
      <Helmet>
        <title>Info - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header />}
          foot={
            <div className="columns">
              <div className="column is-1">
                {/* MENU BUTTON */}
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img src={require('../../../images/arrow-back-gray-icn.svg').default} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <FormattedMessage id="app.shared.docs" defaultMessage="Docs" />
                </SmallTag>
                <Title.H1>
                  {phase.sort} :: {phase.name}
                </Title.H1>
                <StepHeader />
              </div>
            </div>
            <div className="columns">
              <div className="column is-half has-text-left">
                <Docs />
              </div>
              <div className="column is-half has-text-left">
                <Notes />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default Info;
