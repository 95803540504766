import getMessages from '../locales';
// import numbers from './libs/numbers';
import time from './libs/time';

const localeData = getMessages();

const language =
  process.env.REACT_APP_FORCE_LANG ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

console.log('Current language:', languageWithoutRegionCode);
time(languageWithoutRegionCode);
// numbers(languageWithoutRegionCode);

// Try full locale, try locale without region code, fallback to 'en'
const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;

export { messages, language, languageWithoutRegionCode };
