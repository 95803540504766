import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-paginating';

import { userPropTypes } from '../../../../../libs/proptypes';
import Operator from './Operator';
import Button from '../../../../../components/Button';

const PAGE_LIMIT = 8;

class Operators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }
  static defaultProps = {
    operators: [],
  };
  static propTypes = {
    operators: PropTypes.arrayOf(userPropTypes.isRequired),
    onClick: PropTypes,
  };
  handlePageChange = (page) => {
    this.setState({
      currentPage: page,
    });
  };
  paginate(operators, pageNumber) {
    const start = pageNumber - 1; // because pages logically start with 1, but technically with 0
    return operators.slice(start * PAGE_LIMIT, (start + 1) * PAGE_LIMIT);
  }
  render() {
    const { currentPage } = this.state;
    const { operators, onClick } = this.props;
    const total = operators.length;
    const pageCount = Math.ceil(total / PAGE_LIMIT);
    return (
      <Pagination total={total} limit={PAGE_LIMIT} pageCount={pageCount} currentPage={currentPage}>
        {({
          pages,
          currentPage,
          hasNextPage,
          hasPreviousPage,
          previousPage,
          nextPage,
          totalPages,
          getPageItemProps,
        }) => (
          <div className="columns">
            <div className="column is-narrow is-flex">
              {hasPreviousPage && (
                <Button
                  isFullWidth
                  {...getPageItemProps({
                    pageValue: previousPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {'<'}
                </Button>
              )}
            </div>
            <div className="column">
              <div className="columns is-multiline">
                {this.paginate(operators, currentPage).map((operator) => (
                  <Operator operator={operator} key={operator._id} onClick={() => onClick(operator._id)} />
                ))}
              </div>
            </div>
            <div className="column is-narrow is-flex">
              {hasNextPage && (
                <Button
                  isFullWidth
                  {...getPageItemProps({
                    pageValue: nextPage,
                    onPageChange: this.handlePageChange,
                  })}
                >
                  {'>'}
                </Button>
              )}
            </div>
          </div>
        )}
      </Pagination>
    );
  }
}

Operators.defaultProps = {
  pageSize: 8,
};
Operators.propTypes = {
  pageSize: PropTypes.number,
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pin: PropTypes.string,
      role: PropTypes.string,
      image: PropTypes.string,
    })
  ),
};
export default Operators;
