import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import Background from '../../../../../components/Layout/Background';
import Title from '../../../../../components/Title';
import Button from '../../../../../components/Button';
import themes from '../../../../../libs/themes';
import Header from '../../../../../components/Header';

function QuantityMenu({ container, onClose }) {
  const theme = themes.default;

  const history = useHistory();
  const { containerId, produced } = useParams();

  return ReactDOM.createPortal(
    <div className="is-overlay" style={{ background: 'white' }}>
      <ThemeProvider theme={theme}>
        <Background
          hasSessions={false}
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth theme={themes.default.buttons} onClick={onClose} data-cy="application-button-back">
                  <img src={require('../../../../../images/arrow-back-gray-icn.svg').default} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <div className="columns has-text-centered is-multiline">
            {/* LOAD */}
            <div className="column is-half is-offset-3">
              <Button
                isFullWidth
                theme={themes.default.buttons}
                onClick={() => history.push(`/application/container-load/${containerId}/${produced || ''}`)}
                data-cy="application-container_quantity-load"
              >
                <FormattedMessage id="app.pages.application.container_content.load" defaultMessage="Load" />
              </Button>
            </div>
            {/* UNLOAD */}
            <div className="column is-half is-offset-3">
              <Button
                isFullWidth
                theme={themes.default.buttons}
                onClick={() => history.push(`/application/container-unload/${containerId}/${produced || ''}`)}
                data-cy="application-container_quantity-unload"
              >
                <FormattedMessage id="app.pages.application.container_content.unload" defaultMessage="Unload" />
              </Button>
            </div>
            {/* EMPTY */}
            <div className="column is-half is-offset-3">
              <Button
                isFullWidth
                theme={themes.default.buttons}
                onClick={() => history.push(`/application/container-empty/${containerId}/${produced || ''}`)}
                data-cy="application-container_quantity-empty"
              >
                <FormattedMessage id="app.pages.application.container_content.empty" defaultMessage="Empty" />
              </Button>
            </div>
          </div>
        </Background>
      </ThemeProvider>
    </div>,
    document.getElementById('modal-container')
  );
}

QuantityMenu.defaultProps = {};
QuantityMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
export default QuantityMenu;
