import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Session from './Session';
import Loader from '../../Loader';
import Avatar from '../../Avatar';
import { statuses } from '../../../libs/themes';

const StatusCircle = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  border: 0.3rem solid rgba(245, 245, 245, 0.5);
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out 250ms;
  color: ${({ theme }) => theme.fg};
  background-color: ${({ theme }) => theme.border};
`;

function SessionsList({ subscribeToUpdates, loading, data, error, onSessionClick, isDisabled }) {
  const { sessionId } = useParams();
  useEffect(subscribeToUpdates, []);
  // console.log({ loading, data })
  if (loading && !data) {
    return (
      <Session isDisabled>
        <span style={{ fontSize: '3.4rem' }}>
          <Loader message={null} backgroundColor="white" />
        </span>
      </Session>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const { sessions = [] } = data;
  return (
    <>
      {sessions.map((s) => (
        <Session
          key={s._id}
          isActive={sessionId === s._id}
          isDisabled={sessionId === s._id || isDisabled}
          session={s}
          onClick={() => onSessionClick(s)}
          // onLogout={onLogout}
          data-cy="components-sessions-session"
          data-cy-id={s._id}
        >
          <figure className="image is-48x48">
            <Avatar
              size="4.8rem"
              color="transparent"
              fgColor={sessionId === s._id ? '#363636' : 'white'}
              name={s.worker.name}
              src={s.worker.image}
            />
          </figure>
          <StatusCircle theme={statuses[s.status]} />
        </Session>
      ))}
    </>
  );
}

SessionsList.defaultProps = {};
SessionsList.propTypes = {};
export default SessionsList;
