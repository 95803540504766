import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { rgba, darken } from 'polished';

import { buttons } from '../libs/themes';

const StyledButton = styled.a`
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  border: 1px solid transparent;
  box-shadow: none;
  display: inline-flex;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  height: 8.8rem;
  border-radius: 0.8rem;
  font-size: 4rem;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.fg};
  ${({ isFullWidth }) => isFullWidth && 'width: 100%;'}

  &.darker {
    background-color: ${({ theme }) => darken(0.5, theme.bg)};
    border-color: ${({ theme }) => darken(0.45, theme.bg)};
    color: ${({ theme }) => theme.fg};
  }
  &:hover {
    background-color: ${({ theme }) => rgba(theme.bg, 0.85)};
    border-color: ${({ theme }) => rgba(theme.bg, 0.7)};
    color: ${({ theme }) => theme.fg};
  }
  &:active {
    background-color: ${({ theme }) => rgba(theme.bg, 0.85)};
    border-color: ${({ theme }) => rgba(theme.bg, 0.7)};
    color: ${({ theme }) => theme.fg};
  }
  &:focus {
    background-color: ${({ theme }) => rgba(theme.bg, 0.85)};
    border-color: ${({ theme }) => rgba(theme.bg, 0.7)};
    color: ${({ theme }) => theme.fg};
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }) => rgba(theme.bg, 0.7)};
    border-color: ${({ theme }) => rgba(theme.bg, 0.7)};
    color: ${({ theme }) => theme.fg};
  }
`;

/**
 * Button
 *
 * @param children
 * @param theme
 * @param props
 * @returns {*}
 * @constructor
 */
function Button({ children, theme, ...props }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledButton className="button" {...props}>
        {children}
      </StyledButton>
    </ThemeProvider>
  );
}

/**
 * Small Button
 */
const SmallButton = styled(Button)`
  height: 3.6rem;
  border-radius: 0.4rem;
  font-size: 1.7rem;
`;
/**
 * Medium Button
 */
const MediumButton = styled(Button)`
  height: 5.6rem;
  border-radius: 0.4rem;
  font-size: 2rem;
`;

Button.defaultProps = {
  theme: buttons.default,
};
Button.propTypes = {
  isFullWidth: PropTypes.bool,
  theme: PropTypes.shape({
    bg: PropTypes.string.isRequired,
    fg: PropTypes.string.isRequired,
  }),
};

export { Button as default, SmallButton, MediumButton };
