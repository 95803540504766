import React from 'react';
import PropTypes from 'prop-types';
import { FormattedTime } from 'react-intl';
import moment from 'moment';
import TimeFormatter from './TimeFormatter';
import config from '../config';

const UPDATE_INTERVAL = config.showSeconds ? 1000 : 30000;

class Timer extends React.Component {
  static propTypes = {
    ...FormattedTime.propTypes,
    value: PropTypes.number,
    active: PropTypes.bool,
    time: PropTypes.number,
    children: PropTypes.node,
    debug: PropTypes.bool,
  };
  static defaultProps = {
    active: false,
    time: 0,
    debug: false,
  };
  state = {
    now: 0,
    // componentMountedAt: moment()
  };
  UNSAFE_componentWillMount() {
    const { active } = this.props;
    this.initialize(active);
    // if (!this.state.componentMountedAt) this.setState({ componentMountedAt: moment() });
  }
  componentWillUnmount() {
    this.stopTimer();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.time !== this.props.time) {
      this.setState({ componentMountedAt: moment() });
    }
    if (nextProps.active !== this.props.active) {
      this.initialize(nextProps.active);
    }
  }
  initialize(active = false) {
    if (active) this.startTimer();
    if (!active) this.stopTimer();
  }
  startTimer() {
    if (this.loadInterval) return;
    if (!this.state.componentMountedAt) this.setState({ componentMountedAt: moment() });
    this.loadInterval = setInterval(() => {
      this.forceUpdate();
    }, UPDATE_INTERVAL);
  }
  stopTimer() {
    this.loadInterval && clearInterval(this.loadInterval);
    this.loadInterval = false;
  }
  // secondsToHM(seconds) {
  //   const value = +seconds;
  //   const hours = Math.floor(value / 3600);
  //   const minutes = Math.floor((value % 3600) / 60);
  //   const result = `${hours}:${('0' + minutes).substr(-2, 2)}`;
  //   if (result.length === 4) {
  //     return '0' + result;
  //   }
  //   return result;
  // }
  render() {
    const { children, active, time, debug } = this.props;
    const { componentMountedAt } = this.state;
    let seconds = time;
    if (active) {
      const duration = moment.duration(moment().diff(componentMountedAt)).asSeconds();
      seconds += duration;
      if (debug) {
        console.log('Seconds', seconds, 'duration', duration, 'componentMountedAt', componentMountedAt);
      }
    }
    // const value = this.secondsToHM(seconds);
    return (
      <span>
        {children && <span>{children} </span>}
        <TimeFormatter seconds={seconds} active={active} />
      </span>
    );
  }
}

export default Timer;
