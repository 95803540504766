import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import styled from 'styled-components';

import config from '../../../../../config';

const UPDATE_INTERVAL = 1000;

const Container = styled.div`
  .single-chart {
    .blinking {
      animation: blinker 1.5s linear infinite;
    }
  }
`;
class SessionTimer extends React.Component {
  static propTypes = {
    time: PropTypes.number,
    children: PropTypes.node,
  };

  static defaultProps = {
    time: 0,
  };

  state = {
    now: 0,
    componentMountedAt: moment(),
  };

  UNSAFE_componentWillMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  /**
   * Update mount time, to calculate new difference between NOW and MOUNTED_AT time
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.time !== this.props.time) {
      this.setState({ componentMountedAt: moment() });
    }
  }

  startTimer() {
    this.loadInterval = setInterval(() => {
      this.forceUpdate();
    }, UPDATE_INTERVAL);
  }

  stopTimer() {
    this.loadInterval && clearInterval(this.loadInterval);
    this.loadInterval = false;
  }

  calculatePercentage(seconds) {
    return Math.round((+seconds * 100) / 60);
  }

  getHours(value) {
    const hours = Math.floor(value / 3600);
    if (hours < 10) {
      return `0${hours}`;
    }
    return hours || '00';
  }

  getMinutes(value) {
    const minutes = Math.floor((value % 3600) / 60);
    if (minutes < 10) {
      return `0${minutes}`;
    }
    return minutes || '00';
  }

  getSeconds(value) {
    const seconds = Math.floor(value % 60);
    if (seconds < 10) {
      return `0${seconds}`;
    }
    return seconds || '00';
  }

  render() {
    const { intl } = this.props;
    const duration = moment.duration(moment().diff(moment(this.state.componentMountedAt)));
    const total = +duration.asSeconds() + this.props.total;
    const production = +duration.asSeconds() + this.props.production;

    const percentage = this.calculatePercentage(production % 60);
    return (
      <Container className="single-chart">
        <svg viewBox="0 0 36 36" className="circular-chart timing">
          <path
            className="circle-bg"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />

          {percentage > 0 && (
            <path
              className="circle"
              strokeDasharray={`${percentage || 0}, 100`}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          )}

          <text x="18" y="15.2" className="circle-title">
            {intl.formatMessage({ id: 'app.shared.production' })}
          </text>
          <text x="18" y="20" className="circle-value">
            {this.getHours(production)}:{this.getMinutes(production)}
            {config.showSeconds && <>:{this.getSeconds(production)}</>}
          </text>
          <line x1="10" x2="26" y1="21.5" y2="21.5" style={{ stroke: '#cfd8dc', strokeWidth: '.02rem' }} />
          <text x="18" y="25" className="circle-subtitle">
            {intl.formatMessage({ id: 'app.shared.session' })}
          </text>

          <text x="18" y="28" className="circle-subvalue">
            {this.getHours(total)}:{this.getMinutes(total)}
            {config.showSeconds && <>:{this.getSeconds(total)}</>}
          </text>
        </svg>
      </Container>
    );
  }
}

export default injectIntl(SessionTimer);
