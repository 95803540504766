import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import NumberFormatter from '@agile-factory/component-number-formatter';
import classNames from 'classnames';

import SUPPLY_ITEM from './SupplyItem.query.graphql';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import themes from '../../../../libs/themes';
import Background from '../../../../components/Layout/Background';
import Header from '../../../../components/Header';
import Button from '../../../../components/Button';
import Title from '../../../../components/Title';
import { SmallTag } from '../../../../components/Tag';
import StepHeader from '../../../../components/StepHeader';

function SupplyItem() {
  const { phaseId, sessionId, pickingId, stepId } = useParams();
  const history = useHistory();
  const variables = { phaseId, sessionId, pickingId };
  const { data, loading, error } = useQuery(SUPPLY_ITEM, { variables });
  const theme = themes.materialViolet;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;
  const { pickingItem } = session;
  const { container } = pickingItem;

  return (
    <>
      <Helmet>
        <title>Supply Item - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img src={require('../../../../images/arrow-back-gray-icn.svg').default} alt="" />
                </Button>
              </div>
              <div className="column is-4 is-offset-3">
                <Button
                  isFullWidth
                  onClick={() =>
                    history.push(`/application/supply-item-container/${phaseId}/${sessionId}/${pickingId}/${stepId}`)
                  }
                  data-cy="application-supply_item-container-button"
                >
                  <FormattedMessage
                    id="app.pages.application.supply_item-container-button"
                    defaultMessage="Choose container"
                  />
                </Button>
              </div>
              <div className="column is-4">
                <Button
                  isFullWidth
                  disabled={!pickingItem._container}
                  onClick={() =>
                    pickingItem._container &&
                    history.push(`/application/container-content/${pickingItem._container}/${session.produced || ''}`)
                  }
                  data-cy="application-supply_item-container_content-button"
                >
                  <FormattedMessage
                    id="app.pages.application.supply_item-container_content-button"
                    defaultMessage="Manage container"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={themes.materialViolet.buttons}>
                  <FormattedMessage id="app.shared.material" defaultMessage="Material" />
                </SmallTag>
                <Title.H1>
                  {phase.sort} :: {phase.name}
                </Title.H1>
                <StepHeader />
              </div>
            </div>

            <div className="columns">
              <div className="column has-text-left">
                <h4 className="title is-4 w500">
                  {pickingItem.item.code} :: {pickingItem.item.name}
                </h4>
                <div className="separator" style={{ marginBottom: '1.5rem' }} />
                <h4 className="title is-4 has-text-weight-normal">
                  <span className="has-text-grey">
                    <FormattedMessage
                      id="app.pages.application.supply_item.container.title"
                      defaultMessage="Container"
                    />
                  </span>{' '}
                  <span className="has-text-weight-bold">{container?.name ? container.name : '-'}</span>
                </h4>
                <h4 className="title is-4 has-text-weight-normal">
                  <span className="has-text-grey">
                    <FormattedMessage id="app.pages.application.supply_item.content.title" defaultMessage="Content" />
                  </span>{' '}
                  <span
                    className={classNames(
                      'has-text-weight-bold',
                      container && container._item !== pickingItem._item && 'has-text-danger'
                    )}
                  >
                    {container?.item ? `${container.item.code} :: ${container.item.name}` : '-'}
                  </span>
                </h4>
                <h4 className="title is-4 has-text-weight-normal">
                  <span className="has-text-grey">
                    <FormattedMessage id="app.pages.application.supply_item.batch.title" defaultMessage="Batch" />
                  </span>{' '}
                  <span className="has-text-weight-bold">{container?.batch ? container.batch : '-'}</span>
                </h4>
                <h4 className="title is-4 has-text-weight-normal">
                  <span className="has-text-grey">
                    <FormattedMessage id="app.pages.application.supply_item.qty.title" defaultMessage="Quantity" />
                  </span>{' '}
                  <span className="has-text-weight-bold">
                    {container?.current ? (
                      <>
                        <strong>
                          <NumberFormatter value={container.current} /> {container.unit}
                        </strong>
                        {container.capacity && (
                          <>
                            <small>
                              /<NumberFormatter value={container.capacity} />
                            </small>{' '}
                            <small className="fg-bluish-grey">{container.percentage || 0}%</small>
                          </>
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </span>
                </h4>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplyItem.defaultProps = {};
SupplyItem.propTypes = {};
export default SupplyItem;
