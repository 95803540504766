import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Title from '../../../../components/Title';
import Button from '../../../../components/Button';
import toast from '../../../../libs/toast';
import onError from '../../../../libs/error-logger';
import themes from '../../../../libs/themes';
import Background from '../../../../components/Layout';
import Header from '../../../../components/Header';
import { setLoading } from '../../../../actions';
import CONTAINER_ITEM from './ContainerItem.query.graphql';
import CONTAINER_ITEM_MUTATION from './ContainerItem.mutation.graphql';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import GenericError from '../../../errors/GenericError';
import ContainerItemStep1 from './components/ContainerItemStep1';
import ContainerItemStep2 from './components/ContainerItemStep2';

function ContainerItem() {
  const intl = useIntl();
  const [step, setStep] = useState(1);
  const [itemId, setItemId] = useState();
  const [notes, setNotes] = useState();
  const theme = themes.default;
  const { containerId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const variables = { containerId };
  const { loading, error, data } = useQuery(CONTAINER_ITEM, { variables });
  const [mutate] = useMutation(CONTAINER_ITEM_MUTATION, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }
  const { container } = data;

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      await mutate({ variables: { itemId, notes } });
      toast({ title: intl.formatMessage({ id: 'app.shared.save_success' }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Container item - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
          foot={
            step === 1 && (
              <div className="columns">
                <div className="column is-3">
                  <Button isFullWidth onClick={() => history.goBack()} data-cy="application-button-back">
                    <FormattedMessage id="app.shared.cancel" defaultMessage="Cancel" />
                  </Button>
                </div>
                <div className="column is-3 is-offset-6">
                  <Button
                    isFullWidth
                    onClick={() => {
                      setItemId(null);
                      setStep(2);
                    }}
                  >
                    <FormattedMessage id="app.shared.skip" defaultMessage="Skip" />
                  </Button>
                </div>
              </div>
            )
          }
        >
          {step === 1 && (
            <ContainerItemStep1 container={container} setStep={setStep} setItemId={setItemId} itemId={itemId} />
          )}
          {step === 2 && (
            <ContainerItemStep2
              container={container}
              setStep={setStep}
              setNotes={setNotes}
              notes={notes}
              onSubmit={onSubmit}
            />
          )}
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerItem.defaultProps = {};
ContainerItem.propTypes = {};

export default ContainerItem;
