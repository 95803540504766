import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeadContainer = styled.div`
  color: ${({ theme }) => theme.fg};
  padding: 2rem 1.5rem;
  ${({ hasBorderBottom }) => hasBorderBottom && `border-bottom: 0.2rem solid #cfd8dc;`}
`;
function HeadContainer({ children, ...props }) {
  return (
    <StyledHeadContainer className="hero-head" {...props}>
      {children}
    </StyledHeadContainer>
  );
}

HeadContainer.defaultProps = {
  hasBorderBottom: true,
};
HeadContainer.propTypes = {
  hasBorderBottom: PropTypes.bool,
};
export default HeadContainer;
