import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Background from '../../../components/Layout/Background';
import Button from '../../../components/Button';
import { FormattedMessage } from 'react-intl';
import themes from '../../../libs/themes';
import ApprovalBody from '../components/approval/ApprovalBody';
import Header from '../../../components/Header';
import Wrapper from './components/Wrapper';

function Approval() {
  const history = useHistory();
  const { phaseId, sessionId } = useParams();
  return (
    <Wrapper status="APPROVAL">
      <Background
        head={<Header hasMenu />}
        foot={
          <div className="columns">
            {/* FIRST BUTTON */}
            <div className="column is-4">
              {/* MENU BUTTON */}
              <Button
                isFullWidth
                onClick={() => history.push(`/application/menu/${phaseId}/${sessionId}`)}
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.menu" defaultMessage="Menu" />
              </Button>
            </div>
            {/* SECOND BUTTON */}
            {/* THIRD BUTTON */}
            <div className="column is-4 is-offset-4">
              <Button
                isFullWidth
                theme={themes.productionBlue.buttons}
                onClick={() => history.push(`/application/production/${phaseId}/${sessionId}`)}
                data-cy="application-button-next"
              >
                <FormattedMessage id="app.pages.application.approval.buttons.production" defaultMessage="Production" />
              </Button>
            </div>
          </div>
        }
      >
        <ApprovalBody />
      </Background>
    </Wrapper>
  );
}

Approval.defaultProps = {};
Approval.propTypes = {};
export default Approval;
