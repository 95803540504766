import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-apollo';

import SET_STEP from './SetStep.mutation.graphql';
import onError from '../../../../libs/error-logger';
import SetStatus from '../../../../components/SetStatus';

function SetStep({ timeout, status, children }) {
  const [timer, setTimer] = useState(null);
  const { sessionId, stepId } = useParams();
  const variables = { sessionId, stepId };
  const [mutate] = useMutation(SET_STEP, { variables });

  useEffect(() => {
    (async function () {
      async function setStep() {
        console.log(`${new Date().valueOf()} Send step ${stepId}, status ${status} after ${timeout} seconds`);
        try {
          setTimer(
            setTimeout(async () => {
              await mutate();
              console.log(`${new Date().valueOf()} Step ${stepId} sent, status ${status}`);
            }, timeout)
          );
        } catch (error) {
          onError(error);
        }
      }

      await setStep();
      return function cleanup() {
        if (timer) {
          clearTimeout(timer);
          console.log('SetStep Clear timeout! Done!');
        }
      };
    })();
  }, [sessionId, stepId]);
  return (
    <SetStatus status={status} timeout={1200}>
      {children}
    </SetStatus>
  );
}

SetStep.defaultProps = {
  timeout: 1000,
};
SetStep.propTypes = {
  timeout: PropTypes.number,
  status: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default SetStep;
