import React from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import { branch } from 'recompose';

import { withLoading } from './index';
import PHASE from '../queries/PHASE.graphql';

const withPhase = (queryOptions) => (WrappedComponent) => {
  const WithPhase = (props) => {
    const Component = compose(
      graphql(PHASE, {
        options: ({
          match: {
            params: { phaseId },
          },
        }) => ({
          variables: { phaseId },
          fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { phase, loading, error, subscribeToMore } }) => ({
          loading: true,
          error,
          phase,
          subscribeToMore,
        }),
        ...queryOptions,
      }),
      branch(({ loading, phase }) => !phase && loading, withLoading())
    )(WrappedComponent);
    return <Component {...props} />;
  };
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithPhase.displayName = `withPhase(${wrappedComponentName})`;
  return WithPhase;
};

export default withPhase;
