import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { withPhase } from '../../../hocs';
import { setLoading, setNotes } from '../../../actions';
import { phasePropTypes } from '../../../libs/proptypes';
import { MediumButton } from '../../../components/Button';
import SET_STATUS from './SetStatus.graphql';
import CLOSE_SESSION from './Notes.mutation.graphql';
import REPORT_ISSUE from './ReportIssue.mutation.graphql';
import onError from '../../../libs/error-logger';
import OnScreenKeyboard from '../../../components/OnScreenKeyboard';
import themes, { buttons } from '../../../libs/themes';
import Title from '../../../components/Title';
import Background from '../../../components/Layout';
import { SmallTag } from '../../../components/Tag';
import Header from '../../../components/Header';
import StepHeader from '../../../components/StepHeader';

class Notes extends React.Component {
  static defaultProps = {
    loading: true,
  };

  static propTypes = {
    phase: phasePropTypes,
    loading: PropTypes.bool,
  };

  componentDidMount = () => {
    this.handleFocus();
  };

  state = {
    inputNode: null,
    notes: '',
  };

  handleFocus = () => {
    this.setState({ inputNode: this.input });
  };

  onSubmit = async () => {
    const { setStatus, reportIssue, closeSession, match, history, status, message, dispatch } = this.props;
    const { phaseId, sessionId } = match.params;
    const { notes } = this.state;
    const { produced, scrap } = this.props.session;
    const session = { scrap, produced, notes };
    dispatch(setLoading(true));
    try {
      if (status === 'ERROR') {
        await reportIssue({ variables: { sessionId, message } });
      }
      await closeSession({ variables: { sessionId, session } });
      await setStatus({ variables: { sessionId, status } });
      history.push(`/application/end/${phaseId}`);
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  };

  onBack = () => {
    const { dispatch, history } = this.props;
    dispatch(setNotes());
    history.goBack();
  };

  onChange = (event) => {
    this.setState({ notes: event.target.value });
  };

  render() {
    const { intl, status } = this.props;
    const { inputNode, notes } = this.state;
    const theme = themes.default;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Notes - Agile Factory</title>
        </Helmet>
        <ThemeProvider theme={theme}>
          <Background head={<Header hasPhaseInfo />}>
            <>
              <div className="columns">
                <div className="column">
                  <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                    <Choose>
                      <When condition={status === 'INTERRUPT'}>
                        <FormattedMessage id="app.shared.delivery.partial" defaultMessage="Partial delivery" />
                      </When>
                      <When condition={status === 'DONE'}>
                        <FormattedMessage id="app.shared.delivery.final" defaultMessage="Final delivery" />
                      </When>
                      <Otherwise>
                        <FormattedMessage id="app.shared.delivery" defaultMessage="Delivery" />
                      </Otherwise>
                    </Choose>
                  </SmallTag>
                  <Title.H1>
                    <FormattedMessage id="app.pages.application.notes.title" defaultMessage="Add a note" />
                  </Title.H1>
                  <StepHeader />
                </div>
                <div className="column is-3">
                  <div className="columns">
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.default}
                        onClick={this.onBack}
                        data-cy="application-button-back"
                      >
                        <FormattedMessage id="app.shared.back" defaultMessage="Back" />
                      </MediumButton>
                    </div>
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.productionBlue}
                        onClick={this.onSubmit}
                        data-cy="application-button-ok"
                      >
                        <FormattedMessage id="app.shared.ok" defaultMessage="Ok" />
                      </MediumButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column has-text-centered">
                  <div className="separator" />
                  <textarea
                    name="operator-notes"
                    id="operator-notes"
                    data-cy="application-notes-input"
                    rows="6"
                    className="title is-4"
                    value={notes}
                    autoFocus
                    placeholder={intl.formatMessage({
                      id: 'app.components.notes.placeholder',
                      defaultMessage: 'Do you want to write a message?',
                    })}
                    onFocus={this.handleFocus}
                    ref={(input) => {
                      this.input = input;
                    }}
                    onInput={this.onChange}
                  />
                </div>
              </div>
              <OnScreenKeyboard inputNode={inputNode} />
            </>
          </Background>
        </ThemeProvider>
      </>
    );
  }
}

export default compose(
  injectIntl,
  withPhase(),
  graphql(SET_STATUS, { name: 'setStatus' }),
  graphql(CLOSE_SESSION, { name: 'closeSession' }),
  graphql(REPORT_ISSUE, { name: 'reportIssue' }),
  connect(({ delivery }) => ({
    status: delivery.status,
    session: delivery.session,
    message: delivery.error,
  }))
)(Notes);
