import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import themes from '../../../../libs/themes';
import Title from '../../../../components/Title';
import SessionProductivity from './components/SessionProductivity';
import BatchProductivity from './components/BatchProductivity';
import SessionTimer from './components/SessionTimer';
import { SmallTag } from '../../../../components/Tag';
import StepHeader from '../../../../components/StepHeader';
import PRODUCTION from './ProductionBody.query.graphql';
import LoadingIndicator from '../../../../components/LoadingIndicator';

/**
 * Production page
 *
 * @param stepName
 * @constructor
 */
function ProductionBody({ stepName }) {
  const { phaseId, sessionId } = useParams();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(PRODUCTION, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;
  return (
    <>
      <Helmet>
        <title>Production - Agile Factory</title>
      </Helmet>
      <div className="columns">
        <div className="column">
          <SmallTag isUpperCase isLight theme={themes.productionBlue.buttons}>
            {stepName || <FormattedMessage id="app.shared.production" defaultMessage="Production" />}
          </SmallTag>
          <Title.H1>
            {phase.sort} :: {phase.name}
          </Title.H1>
          <StepHeader type="PRODUCTION" />
        </div>
      </div>

      <div className="columns">
        {/* SESSION TIMER */}
        <div className="column has-text-centered">
          <SessionTimer production={session.timers.PRODUCTION} total={session.timers.TOTAL || 0} />
        </div>
        {/* BATCH PRODUCTIVITY */}
        <div className="column has-text-centered">
          <BatchProductivity produced={phase.produced || 0} percentage={phase.percentage || 0} qty={phase.qty} />
        </div>
        {/* SESSION PRODUCTIVITY */}
        <div className="column has-text-centered">
          <SessionProductivity
            time={session.timers.PRODUCTION || 0}
            produced={session.produced || 0}
            average={phase.average}
            active
          />
        </div>
      </div>
    </>
  );
}

ProductionBody.defaultProps = {
  stepName: 'PRODUCTION',
};
ProductionBody.propTypes = {
  stepName: PropTypes.string,
};

export default ProductionBody;
