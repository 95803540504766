import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function SessionNote({ phase, notes, date, worker }) {
  return (
    <h4 className="title is-4">
      {phase && (
        <small>
          {phase.sort} :: {phase.name} {phase.product && phase.product.code} -{' '}
        </small>
      )}
      <small>
        {worker.name} <i>({moment(date).fromNow()})</i>
      </small>
      <br />
      {notes}
    </h4>
  );
}

SessionNote.defaultProps = {};
SessionNote.propTypes = {
  phase: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  notes: PropTypes.string,
  date: PropTypes.string,
  worker: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
export default SessionNote;
