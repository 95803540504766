import React from 'react';
import { FormattedTime } from 'react-intl';
import Title from './Title';

class Clock extends React.PureComponent {
  static state = {
    timer: undefined,
    date: Date.now(),
  };

  UNSAFE_componentWillMount() {
    const timer = setInterval(() => this.setState({ date: Date.now() }), 30000);
    this.setState({ timer });
  }
  componentWillUnmount() {
    const { timer } = this.state;
    if (timer) {
      clearTimeout(timer);
    }
  }
  render() {
    const { date } = this.state;
    return (
      <Title.H3>
        <FormattedTime
          value={date || Date.now()}
          hour="numeric"
          minute="2-digit"
          hour12={false}
          // second="2-digit"
        />
      </Title.H3>
    );
  }
}

export default Clock;
