import swal from 'sweetalert2';

/**
 * Toast message
 * @param options
 */

function toast(options = {}) {
  console.log('>> toast');
  swal.fire({
    icon: 'success',
    title: 'Message is missing',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
    ...options,
  });
}
export default toast;
