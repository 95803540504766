import {
  RESET_CONFIG,
  SET_HMI_CONFIGURATION,
  SET_STATION,
  SET_MACHINE,
  SET_PROGRAM,
  SET_FEATURES,
  ADD_OPERATION,
  REMOVE_OPERATION,
  RESET_OPERATIONS,
  ADD_MEMBER,
  REMOVE_MEMBER,
  RESET_TEAM,
} from '../actions';

const DEFAULT_CONFIGURATION = {
  QTY: null,
  STATION_ID: null,
  CHOOSE_PHASE: true,
  ON_SCREEN_KEYBOARD: true,
  MULTI_SESSION: true,
  INTERNAL_PDF: true,
  DOWNLOAD_ATTACHMENT: true,
  HMI_TIMEOUT: undefined,
  PRODUCTION_ORDER_SORT: 'deadline sort name',
  OPERATION_SORT: 'sort',
};

function initialState(hmiConfiguration = DEFAULT_CONFIGURATION) {
  return {
    hmiId: window.HMI_ID,
    stationId: hmiConfiguration.STATION_ID,
    machineId: null,
    programId: null,
    team: [],
    operations: [],
    hmiConfiguration,
    features: [],
  };
}

/**
 * Config reducer
 * Contains HMI configuration
 * @param state
 * @param action
 * @returns {*}
 */
function configReducer(state = initialState(), action) {
  const { hmiConfiguration, features, stationId } = state;
  switch (action.type) {
    case RESET_CONFIG:
      console.log('Resetting configuration', { ...state, ...initialState(hmiConfiguration), features });
      return { ...state, ...initialState(hmiConfiguration), features };
    case SET_HMI_CONFIGURATION:
      return { ...state, stationId: stationId || action.value.STATION_ID, hmiConfiguration: action.value };
    case SET_FEATURES:
      return { ...state, features: action.value };

    case SET_STATION:
      return { ...state, stationId: action.value };
    case SET_MACHINE:
      return { ...state, machineId: action.value };
    case SET_PROGRAM:
      return { ...state, programId: action.value };

    case ADD_OPERATION:
      return { ...state, operations: [...new Set([...state.operations, action.operation])] };
    case REMOVE_OPERATION:
      return { ...state, operations: state.operations.filter((operation) => operation !== action.operation) };
    case RESET_OPERATIONS:
      return { ...state, operations: [] };

    case ADD_MEMBER:
      return { ...state, team: [...new Set([...state.team, action.member])] };
    case REMOVE_MEMBER:
      return { ...state, team: state.team.filter((member) => member !== action.member) };
    case RESET_TEAM:
      return { ...state, team: [] };
    default:
      return state;
  }
}

export default configReducer;
