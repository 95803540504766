import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { ThemeProvider } from 'styled-components';

import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import Button from '../../../../components/Button';
import Background from '../../../../components/Layout';
import BackImage from '../../../../images/arrow-back-gray-icn.svg';
import { setProgram } from '../../../../actions';
import Header from '../../../../components/Header';
import Clock from '../../../../components/Clock';
import Programs from './components/Programs';
import PROGRAMS from './AdvancedProgram.query.graphql';
import themes from '../../../../libs/themes';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';

function AdvancedProgram() {
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const { jobId, phaseId } = useParams();
  const { machineId, programId } = useSelector(({ config }) => config);

  const { data, loading, error } = useQuery(PROGRAMS, { variables: { machineId } });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { programs } = data;

  function onClick(id) {
    if (programId === id) {
      return dispatch(setProgram());
    }
    dispatch(setProgram(id));
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choose program - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column has-text-centered">
                <Clock />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  onClick={() => history.push(`/configuration/advanced/menu/${jobId}/${phaseId}`)}
                  data-cy="application-button-back"
                >
                  <img src={BackImage} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.programs.title"
                    defaultMessage="Choose program"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.programs.subtitle"
                    defaultMessage="Tell me on which program you will work"
                  />
                </Subtitle.S3>

                <Programs onClick={onClick} programs={programs} />
                {programs.length === 0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.configuration.advanced.programs.empty"
                      defaultMessage="Sorry, no programs available"
                    />
                  </Title.H2>
                )}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

AdvancedProgram.defaultProps = {};
AdvancedProgram.propTypes = {};

export default AdvancedProgram;
