import swal from 'sweetalert2';
import intl from './format-message';
import onError from './error-logger';

/**
 * Confirm an action
 *
 * @param confirm {function} confirm callback
 * @param cancel {function} cancel callback
 * @param options {object} sweet alert configuration
 */
async function confirmAction({ confirm, cancel = () => true, options = {} }) {
  const confirmOptions = {
    title: intl.formatMessage({ id: 'app.shared.confirm' }),
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: intl.formatMessage({ id: 'app.shared.ok' }),
    cancelButtonText: intl.formatMessage({ id: 'app.shared.cancel' }),
    heightAuto: false,
    ...options,
  };
  try {
    const result = await swal.fire(confirmOptions);
    if (typeof result.value === 'undefined') {
      return cancel();
    }
    return confirm(result.value);
  } catch (error) {
    onError(error);
  }
}

export default confirmAction;
