// Configuration reducer
export const SET_STATION = 'SET_STATION';
export const SET_MACHINE = 'SET_MACHINE';
export const SET_PROGRAM = 'SET_PROGRAM';
export const SET_HMI_CONFIGURATION = 'SET_HMI_CONFIGURATION';
export const SET_FEATURES = 'SET_FEATURES';

export function setStation(value = null) {
  return { type: SET_STATION, value };
}

export function setMachine(value = null) {
  return { type: SET_MACHINE, value };
}
export function setProgram(value = null) {
  return { type: SET_PROGRAM, value };
}

export function setHmiConfiguration(value = null) {
  return { type: SET_HMI_CONFIGURATION, value };
}

export function setFeatures(value = []) {
  return { type: SET_FEATURES, value };
}

// Linked operations

export const ADD_OPERATION = 'ADD_OPERATION';
export const REMOVE_OPERATION = 'REMOVE_OPERATION';
export const RESET_OPERATIONS = 'RESET_OPERATIONS';

export function addOperation(operation) {
  return { type: ADD_OPERATION, operation };
}

export function removeOperation(operation) {
  return { type: REMOVE_OPERATION, operation };
}

export function resetOperations() {
  return { type: RESET_OPERATIONS };
}

// Team reducer
export const ADD_MEMBER = 'ADD_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const RESET_TEAM = 'RESET_TEAM';

export function addMember(member) {
  return { type: ADD_MEMBER, member };
}

export function removeMember(member) {
  return { type: REMOVE_MEMBER, member };
}

export function resetTeam() {
  return { type: RESET_TEAM };
}
