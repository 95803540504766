import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation } from 'react-apollo';
import { ThemeProvider } from 'styled-components';

import Button from '../../../components/Button';
import Title from '../../../components/Title';
import Subtitle from '../../../components/Subtitle';
import { setUser } from '../../../actions';
import Header from '../../../components/Header';
import Clock from '../../../components/Clock';
import OperatorsGrouped from './components/OperatorsGrouped';
import OPERATORS from './ChooseOperator.query.graphql';
import CREATE_TOKEN from './ChooseOperator.mutation.graphql';
import onError from '../../../libs/error-logger';
import Background from '../../../components/Layout';
import themes from '../../../libs/themes';
import BackImage from '../../../images/arrow-back-gray-icn.svg';
import LoadingIndicator from '../../../components/LoadingIndicator';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import ScanWorkId from './components/ScanWorkId';

const PAGE_SIZE = 8;

/**
 * ChooseOperator
 *
 * @returns {*}
 * @constructor
 */
function ChooseOperator() {
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const { index } = useParams();
  const { stationId, hmiConfiguration } = useSelector(({ config }) => config);
  const { STATION_ID } = hmiConfiguration;

  const [mutate] = useMutation(CREATE_TOKEN);
  const { data, loading, error } = useQuery(OPERATORS, { variables: { stationId } });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { operators } = data;

  async function onClick({ workerId, code }) {
    const { CHOOSE_PHASE } = hmiConfiguration;
    const variables = { workerId, code };
    try {
      const result = await mutate({ variables });
      const { worker } = result.data;
      dispatch(setUser(worker));
      if (CHOOSE_PHASE) {
        return history.push('/configuration/order');
      }
      return history.push('/configuration/next-phase');
    } catch (error) {
      onError(error);
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column has-text-centered">
                <Clock />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={theme.buttons}
                  onClick={() => {
                    if (index) {
                      return history.push('/configuration/operator');
                    }
                    if (STATION_ID) {
                      return history.push('/');
                    }
                    return history.push('/configuration/station');
                  }}
                >
                  <img src={BackImage} alt="" />
                </Button>
              </div>
              <div className="column is-4 is-offset-3">
                <ScanWorkId onSubmit={onClick} />
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage id="app.pages.configuration.operators.title" defaultMessage="Choose user" />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.configuration.operators.subtitle"
                    defaultMessage="Tell me who you are"
                  />
                </Subtitle.S3>
              </div>
            </div>
            <OperatorsGrouped onClick={onClick} operators={operators} pageSize={PAGE_SIZE} />
            {operators.length === 0 && (
              <Title.H2>
                <FormattedMessage
                  id="app.pages.configuration.operators.empty"
                  defaultMessage="Sorry, no operator assigned to this station, contact a responsible"
                />
              </Title.H2>
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ChooseOperator.defaultProps = {};
ChooseOperator.propTypes = {};

export default ChooseOperator;
