import React from 'react';
import ReactDOM from 'react-dom';
// Styles
import '@agile-factory/styles-icons';
import 'bulma/css/bulma.css';
import 'react-screen-keyboard/src/Keyboard.css';
import './css/fonts.css';
import './css/circle.css';
import './css/helpers.css';
import './css/loader.css';
import './css/style.css';
// Routing
import Routes from './routes';
// Localization

import { unregister } from './registerServiceWorker';

ReactDOM.render(React.createElement(Routes), document.getElementById('root'));
unregister();
