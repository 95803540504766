import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-paginating';

import Operator from './Operator';

function Operators({ operators, onClick, pageSize }) {
  const [page, setPage] = useState(1);
  const total = operators.length;
  const pageCount = Math.ceil(total / pageSize);
  function paginate(operators, pageNumber) {
    const start = pageNumber - 1; // because pages logically start with 1, but technically with 0
    return operators.slice(start * pageSize, (start + 1) * pageSize);
  }
  return (
    <Pagination total={total} limit={pageSize} pageCount={pageCount} currentPage={page}>
      {({
        // pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        // totalPages,
        getPageItemProps,
      }) => (
        <div className="columns">
          <div className="column is-narrow is-flex">
            {hasPreviousPage && (
              <a
                className="button is-large is-light"
                style={{ alignSelf: 'center' }}
                {...getPageItemProps({
                  pageValue: previousPage,
                  onPageChange: setPage,
                })}
              >
                {'<'}
              </a>
            )}
          </div>
          <div className="column">
            <div className="columns is-multiline">
              {paginate(operators, currentPage).map((operator) => (
                <Operator operator={operator} key={operator._id} onClick={() => onClick({ workerId: operator._id })} />
              ))}
            </div>
          </div>
          <div className="column is-narrow is-flex">
            {hasNextPage && (
              <a
                className="button is-large is-light"
                style={{ alignSelf: 'center' }}
                {...getPageItemProps({
                  pageValue: nextPage,
                  onPageChange: setPage,
                })}
              >
                {'>'}
              </a>
            )}
          </div>
        </div>
      )}
    </Pagination>
  );
}

Operators.defaultProps = {
  pageSize: 8,
};
Operators.propTypes = {
  pageSize: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pin: PropTypes.string,
      role: PropTypes.string,
      image: PropTypes.string,
    })
  ),
};
export default Operators;
