import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';

import Background from '../../../../components/Layout/Background';
import themes from '../../../../libs/themes';
import Header from '../../../../components/Header';
import SUPPLY_ITEM from './SupplyItemContainer.query.graphql';
import SUPPLY_ITEM_MUTATION from './SupplyItemContainer.mutation.graphql';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';
import Subtitle from '../../../../components/Subtitle';
import ContainerList from './components/ContainerList';
import onError from '../../../../libs/error-logger';
import toast from '../../../../libs/toast';
import confirmAction from '../../../../libs/confirm_action';

function SupplyItemContainer() {
  const intl = useIntl();
  const [containerId, setContainerId] = useState();
  const { phaseId, sessionId, pickingId } = useParams();
  const history = useHistory();
  const { stationId } = useSelector(({ config }) => config);
  const variables = { phaseId, sessionId, pickingId, stationId };
  const { data, loading, error } = useQuery(SUPPLY_ITEM, { variables });
  const [mutate] = useMutation(SUPPLY_ITEM_MUTATION, { variables: { sessionId, pickingId } });
  const theme = themes.materialViolet;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session, containers } = data;
  const { pickingItem } = session;

  async function checkContent(_container) {
    // TODO: If content is different, shows warning, you will empty container and change content
    const container = containers.find((container) => container._id === _container);
    if (container && pickingItem._item !== container._item) {
      return confirmAction({
        confirm: () => onSubmit(_container),
        options: {
          text: intl.formatMessage({
            id: 'app.pages.application.supply_item_container.warning.item_different',
            defaultMessage: 'Warning, container item is different',
          }),
        },
      });
    }
    await onSubmit(_container);
  }

  async function onSubmit(_container) {
    try {
      const variables = {
        containerId: _container,
        itemId: pickingItem._item,
        picking: { _item: pickingItem._item, _container },
      };
      await mutate({ variables });
      toast({ title: intl.formatMessage({ id: 'app.shared.save_success' }) });
      return history.goBack();
    } catch (error) {
      onError(error);
    }
  }

  return (
    <>
      <Helmet>
        <title>Supply Item Container - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <FormattedMessage id="app.shared.cancel" defaultMessage="Cancel" />
                </Button>
              </div>
              <div className="column">
                <Button
                  theme={themes.primary.buttons}
                  isFullWidth
                  onClick={() => checkContent(containerId)}
                  data-cy="application-supply_item_container-button-save"
                >
                  <FormattedMessage id="app.shared.save" defaultMessage="Save" />
                </Button>
              </div>
              <div className="column">
                <Button
                  isFullWidth
                  onClick={() => onSubmit(null)}
                  data-cy="application-supply_item_container-button-unset"
                >
                  <FormattedMessage id="app.shared.unset" defaultMessage="Unset" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.supply_item_container.title"
                    defaultMessage="Choose container"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.application.supply_item_container.subtitle"
                    defaultMessage="Configure container"
                  />
                </Subtitle.S3>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <ContainerList
                  containers={containers}
                  containerId={containerId || session.pickingItem?._container}
                  onClick={setContainerId}
                />
              </div>
              {containers.length === 0 && (
                <div className="column">
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.application.supply_item_container.empty"
                      defaultMessage="Containers not configured, please contact a manager"
                    />
                  </Title.H2>
                </div>
              )}
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplyItemContainer.defaultProps = {};
SupplyItemContainer.propTypes = {};
export default SupplyItemContainer;
