import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Title from '../../../../../components/Title';
import { MediumButton } from '../../../../../components/Button';
import OnScreenKeyboard from '../../../../../components/OnScreenKeyboard';
import themes, { buttons } from '../../../../../libs/themes';
import Subtitle from '../../../../../components/Subtitle';
import toast from '../../../../../libs/toast';

function ContainerLoadStep2({ scrap, setScrap, container, setStep }) {
  const intl = useIntl();
  const inputEl = useRef(null);
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);
  function onNext() {
    if (isNaN(scrap)) {
      toast({ title: intl.formatMessage({ id: 'app.shared.nan' }), icon: 'error' });
      return;
    }
    setStep(3);
  }
  return (
    <>
      <div className="columns">
        <div className="column">
          <Title.H1>
            <FormattedMessage id="app.pages.application.container_load.scrap.title" defaultMessage="How many scrap?" />
          </Title.H1>
          <Subtitle.S3 theme={themes.secondary} style={{ marginBottom: '.5rem' }}>
            {container.name}
          </Subtitle.S3>
          {container.item && (
            <Subtitle.S4 theme={themes.secondary}>
              {container.item.code} :: {container.item.name}
            </Subtitle.S4>
          )}
        </div>
        <div className="column is-3">
          <div className="columns">
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.default}
                onClick={() => setStep(1)}
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.back" defaultMessage="Back" />
              </MediumButton>
            </div>
            <div className="column">
              <MediumButton
                isFullWidth
                theme={buttons.productionBlue}
                onClick={onNext}
                data-cy="application-button-next"
              >
                <FormattedMessage id="app.shared.next" defaultMessage="Next" />
              </MediumButton>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-centered">
          <div className="separator" />

          <input
            name="scrap"
            data-cy="application-container_load-scrap-input"
            id="scrap"
            type="text"
            step="any"
            min="0"
            className="title is-1 is-double"
            placeholder="0"
            value={scrap || ''}
            onFocus={() => inputEl.current && inputEl.current.select()}
            ref={inputEl}
            onInput={(event) => setScrap(+event.target.value)}
            style={{
              color: '#455a64',
              width: '100%',
              border: 'none',
              outline: 'none',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          />
        </div>
      </div>
      <OnScreenKeyboard inputNode={inputEl.current} />
    </>
  );
}

ContainerLoadStep2.defaultProps = {
  scrap: null,
};
ContainerLoadStep2.propTypes = {
  scrap: PropTypes.number,
  setScrap: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ContainerLoadStep2;
