import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useMutation } from 'react-apollo';

import { statuses } from '../libs/themes';
import SET_STATUS from './SetStatus.mutation.graphql';
import onError from '../libs/error-logger';

function SetStatus({ timeout, status, children }) {
  const { sessionId, stepId } = useParams();
  const variables = { sessionId, status, stepId };
  const [mutate] = useMutation(SET_STATUS, { variables });

  useEffect(() => {
    console.log(`${new Date().valueOf()} Sending status ${status} in ${timeout} ms`);
    let sent = false;
    const timer = setTimeout(async () => {
      try {
        await mutate();
      } catch (error) {
        onError(error);
      }
      console.log(`${new Date().valueOf()}   Status ${status} sent`);
      sent = true;
    }, timeout);

    return () => {
      if (!sent) {
        clearTimeout(timer);
        console.log(`${new Date().valueOf()}   Status ${status} canceled`);
      }
    };
  }, [sessionId, status, stepId]);
  const theme = statuses[status];
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

SetStatus.defaultProps = {
  timeout: 1000,
  status: 'PAUSE',
};
SetStatus.propTypes = {
  timeout: PropTypes.number,
  status: PropTypes.string,
  children: PropTypes.node,
};
export default SetStatus;
