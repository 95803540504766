import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSession = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  line-height: 4rem;
  font-size: 2rem;
  margin-bottom: 1rem;
  vertical-align: top;
  display: inline-flex;
  box-shadow: none;
  align-items: center;
  border: 1px solid transparent;
  justify-content: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  color: ${({ isActive }) => (isActive ? '#263238' : 'white')};
  background-color: ${({ isActive }) => (isActive ? 'white' : '#455a64')};
  position: relative !important;
`;

function Session({ children, onClick, isActive, isDisabled, ...props }) {
  function handleClick() {
    if (isDisabled) {
      return;
    }
    onClick();
  }

  return (
    <StyledSession className="button" onClick={handleClick} isActive={isActive} isDisabled={isDisabled} {...props}>
      {children}
    </StyledSession>
  );
}

Session.defaultProps = {
  isActive: false,
  isDisabled: false,
};
Session.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};
export default Session;
