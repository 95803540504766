import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function MachineParameters({ parameters }) {
  return (
    <>
      {/* LISTENING */}
      <h4 className="title is-4 has-text-weight-normal">
        <span className="has-text-grey">
          <FormattedMessage
            id="app.pages.application.machine.settings.parameter.listening"
            defaultMessage="Listening"
          />
        </span>{' '}
        <span className="has-text-weight-bold">
          {parameters.LISTENING ? (
            <small className="has-text-success">
              <FormattedMessage
                id="app.pages.application.machine.settings.listening"
                defaultMessage="Communication available"
              />{' '}
              <span role="img" aria-label="Listening">
                📡
              </span>
            </small>
          ) : (
            <small className="has-text-danger">
              <FormattedMessage
                id="app.pages.application.machine.settings.not_listening"
                defaultMessage="Communication not available"
              />{' '}
              <span role="img" aria-label="Not listening">
                🚫
              </span>
            </small>
          )}
        </span>
      </h4>
      {/* STATUS */}
      <h4 className="title is-4 has-text-weight-normal">
        <span className="has-text-grey">
          <FormattedMessage id="app.pages.application.machine.settings.parameter.status" defaultMessage="Status" />
        </span>{' '}
        <span className="has-text-weight-bold">{parameters.STATUS}</span>
      </h4>
      {/* ERROR */}
      <h4 className="title is-4 has-text-weight-normal">
        <span className="has-text-grey">
          <FormattedMessage id="app.pages.application.machine.settings.parameter.error" defaultMessage="Error" />
        </span>{' '}
        {parameters.ERROR ? (
          <span role="img" aria-label="error">
            ⚠️
          </span>
        ) : (
          '-'
        )}
      </h4>
      {/* ORDER */}
      <h4 className="title is-4 has-text-weight-normal">
        <span className="has-text-grey">
          <FormattedMessage id="app.pages.application.machine.settings.parameter.order" defaultMessage="Order" />
        </span>{' '}
        <span className="has-text-weight-semibold">{parameters.ORDER || '-'}</span>
      </h4>
      {/* PROGRAM */}
      <h4 className="title is-4 has-text-weight-normal">
        <span className="has-text-grey">
          <FormattedMessage id="app.pages.application.machine.settings.parameter.program" defaultMessage="Program" />
        </span>{' '}
        <span className="has-text-weight-semibold">{parameters.PROGRAM || '-'}</span>
      </h4>
      {/* CYCLES */}
      <h4 className="title is-4 has-text-weight-normal">
        <span className="has-text-grey">
          <FormattedMessage id="app.pages.application.machine.settings.parameter.cycles" defaultMessage="Pieces" />
        </span>{' '}
        <span className="has-text-weight-bold">{parameters.CYCLES || '-'}</span>
      </h4>
      {/* QUANTITY */}
      <h4 className="title is-4 has-text-weight-normal">
        <span className="has-text-grey">
          <FormattedMessage id="app.pages.application.machine.settings.parameter.qty" defaultMessage="Quantity" />
        </span>{' '}
        <span className="has-text-weight-semibold">{parameters.QTY || '-'}</span>
      </h4>
    </>
  );
}

MachineParameters.defaultProps = {};
MachineParameters.propTypes = {
  parameters: PropTypes.shape({
    PROGRAM: PropTypes.string,
    STATUS: PropTypes.string,
    ORDER: PropTypes.string,
    ERROR: PropTypes.bool,
    LISTENING: PropTypes.bool,
    QTY: PropTypes.number,
    CYCLES: PropTypes.number,
  }).isRequired,
};
export default MachineParameters;
