import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Background from '../../../components/Layout/Background';
import Button from '../../../components/Button';
import CheckBody from '../components/check/CheckBody';
import themes from '../../../libs/themes';
import Header from '../../../components/Header';
import Wrapper from './components/Wrapper';

function Check() {
  const { phaseId, sessionId } = useParams();
  const history = useHistory();
  return (
    <Wrapper status="CHECK">
      <Background
        head={<Header hasMenu />}
        foot={
          <div className="columns">
            {/* FIRST BUTTON */}
            <div className="column is-4">
              {/* MENU BUTTON */}
              <Button
                isFullWidth
                onClick={() => history.push(`/application/menu/${phaseId}/${sessionId}`)}
                data-cy="application-button-back"
              >
                <FormattedMessage id="app.shared.menu" defaultMessage="Menu" />
              </Button>
            </div>
            {/* THIRD BUTTON */}
            <div className="column is-offset-4">
              <Button
                isFullWidth
                theme={themes.primary.buttons}
                onClick={() => history.push(`/application/delivery/${phaseId}/${sessionId}`)}
                data-cy="application-button-next"
              >
                <FormattedMessage id="app.shared.delivery" defaultMessage="Delivery" />
              </Button>
            </div>
          </div>
        }
      >
        <CheckBody />
      </Background>
    </Wrapper>
  );
}

Check.defaultProps = {};
Check.propTypes = {};
export default Check;
