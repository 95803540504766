import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Title from '../../../../components/Title';
import toast from '../../../../libs/toast';
import onError from '../../../../libs/error-logger';
import CONTAINER_LOAD from './ContainerLoad.query.graphql';
import CONTAINER_LOAD_MUTATION from './ContainerLoad.mutation.graphql';
import themes from '../../../../libs/themes';
import Background from '../../../../components/Layout';
import Header from '../../../../components/Header';
import { setLoading } from '../../../../actions';
import CenteredLayout from '../../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import GenericError from '../../../errors/GenericError';
import ContainerLoadStep1 from './components/ContainerLoadStep1';
import ContainerLoadStep2 from './components/ContainerLoadStep2';
import ContainerLoadStep3 from './components/ContainerLoadStep3';

function ContainerLoad() {
  const intl = useIntl();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [qty, setQty] = useState();
  const [scrap, setScrap] = useState();
  const [notes, setNotes] = useState();
  const { containerId } = useParams();
  const dispatch = useDispatch();

  const variables = { containerId };
  const { loading, error, data } = useQuery(CONTAINER_LOAD, { variables });
  const [mutate] = useMutation(CONTAINER_LOAD_MUTATION, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      await mutate({ variables: { qty, scrap, notes } });
      toast({ title: intl.formatMessage({ id: 'app.shared.save_success' }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { container } = data;
  const theme = themes.default;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Container Load - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
        >
          <>
            {step === 1 && <ContainerLoadStep1 container={container} setStep={setStep} setQty={setQty} qty={qty} />}
            {step === 2 && (
              <ContainerLoadStep2 container={container} setStep={setStep} setScrap={setScrap} scrap={scrap} />
            )}
            {step === 3 && (
              <ContainerLoadStep3
                container={container}
                setStep={setStep}
                setNotes={setNotes}
                notes={notes}
                onSubmit={onSubmit}
              />
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerLoad.defaultProps = {};
ContainerLoad.propTypes = {};

export default ContainerLoad;
