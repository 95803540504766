import React from 'react';
import ReactAvatar from 'react-avatar';

function defaultInitials(name, { maxInitials }) {
  return name
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((l) => new RegExp(/^[A-Za-z0-9]+$/).test(l))
    .filter((v) => !!v)
    .slice(0, maxInitials)
    .join('')
    .toUpperCase();
}
const DEFAULT = {
  fontFamily: 'LLCircularWeb, Helvetica Neue, Helvetica, sans-serif',
  round: '10%',
  maxInitials: 2,
  textSizeRatio: 2,
  initials: defaultInitials,
};

function Avatar({ ...rest }) {
  const props = { ...DEFAULT, ...rest };
  return <ReactAvatar {...props} />;
}

export default Avatar;
