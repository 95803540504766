import React from 'react';
import PropTypes from 'prop-types';
import NumberFormatter from '@agile-factory/component-number-formatter';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import themes from '../../../../../libs/themes';
import ListElement from '../../../../../components/ListElement';

function PickingItem({ produced, picking, alertThreshold }) {
  const history = useHistory();
  const { container, item } = picking;
  const producible = picking.remaining / picking.qty; // How many units can be done with that material
  const theme = producible >= alertThreshold ? themes.default.listElement : themes.errorRed.listElement;
  return (
    <ListElement
      onClick={() => history.push(`/application/container-content/${container._id}/${produced || ''}`)}
      theme={theme}
      data-cy="application-supply-item"
      data-cy-id={picking._id}
    >
      <div className="columns is-vcentered is-fullwidth is-marginless">
        <div className="column">
          <ul>
            <li className="title is-4">{item.code}</li>
            <li className="subtitle is-4 fg-secondary">{item.name}</li>
          </ul>
        </div>
        <div className="column is-narrow has-text-right">
          <ul>
            <li className="title is-4">
              {container.name} {container.batch && <small>({container.batch})</small>}
            </li>
            <li className="subtitle is-4 fg-secondary">
              <small>
                <FormattedMessage
                  id="app.pages.application.supply.picking_item.producible"
                  defaultMessage="Producible units"
                />
              </small>{' '}
              <strong>
                <NumberFormatter value={producible} /> {container.unit}
              </strong>
            </li>
          </ul>
        </div>
      </div>
    </ListElement>
  );
}

PickingItem.defaultProps = {};
PickingItem.propTypes = {
  produced: PropTypes.number.isRequired,
  alertThreshold: PropTypes.number.isRequired,
  picking: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
    remaining: PropTypes.number,
    container: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      batch: PropTypes.string,
    }),
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      unit: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
export default PickingItem;
