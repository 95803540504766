import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Avatar from '../../../../../components/Avatar';

const StyledWrapper = styled.div`
  position: relative;

  &.disabled {
  }

  .image {
    margin-bottom: 0.8rem;
    width: 18rem;
  }

  .image {
    margin-bottom: 0.8rem;
  }

  .image,
  a {
    width: 12.8rem;
    height: 12.8rem;
  }
`;

function Container({ container, onClick }) {
  return (
    <StyledWrapper className="column is-3 has-text-centered">
      <figure className="image" style={{ marginBottom: 8 }}>
        <a onClick={() => onClick(container._id)} data-cy="application-containers-container" data-cy-id={container._id}>
          <Avatar size="12.8rem" name={container.name} src={container.image} />
        </a>
      </figure>
      <h4 className="title is-4 w500 has-text-centered">{container.name}</h4>
      {container.item && (
        <p className="subtitle is-4 fg-secondary has-text-centered">
          {container.item.code} <small>{container.item.name}</small>
        </p>
      )}
    </StyledWrapper>
  );
}

Container.defaultProps = {
  containerId: null,
};
Container.propTypes = {
  containerId: PropTypes.string,
  onClick: PropTypes.string.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
};
export default Container;
