import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { ThemeProvider } from 'styled-components';

import Button from '../../../../components/Button';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import Header from '../../../../components/Header';
import OPERATORS from './AdvancedTeam.query.graphql';
import Background from '../../../../components/Layout';
import themes from '../../../../libs/themes';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import CenteredLayout from '../../../../layouts/CenteredLayout';
import { resetTeam } from '../../../../actions';
import OperatorsGrouped from './components/OperatorsGrouped';
import BackImage from '../../../../images/arrow-back-gray-icn.svg';

/**
 * ChooseTeam
 *
 * @returns {*}
 * @constructor
 */
function AdvancedTeam() {
  const theme = themes.default;
  const dispatch = useDispatch();
  const { user, config } = useSelector(({ user, config }) => ({ user, config }));
  const { stationId } = config;
  const history = useHistory();
  const { index, jobId, phaseId } = useParams();

  const { data, loading, error } = useQuery(OPERATORS, { variables: { stationId } });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { operators } = data;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  onClick={() => history.push(`/configuration/advanced/${index ? 'team' : 'menu'}/${jobId}/${phaseId}`)}
                  data-cy="application-button-back"
                >
                  <img src={BackImage} alt="" />
                </Button>
              </div>

              {index === undefined && (
                <div className="column is-4 is-offset-3">
                  <Button
                    isFullWidth
                    theme={theme.buttons}
                    onClick={() => dispatch(resetTeam())}
                    data-cy="configuration-team-reset"
                  >
                    <FormattedMessage id="app.shared.clear" defaultMessage="Clear" />
                  </Button>
                </div>
              )}
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.team.title"
                    defaultMessage="How the team will be composed"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.team.subtitle"
                    defaultMessage="Choose members, clicking on them"
                  />
                </Subtitle.S3>
                <OperatorsGrouped operators={operators.filter((o) => o._id !== user.worker._id)} />
                {operators.filter((o) => o._id !== user.worker._id).length === 0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.configuration.advanced.team.empty"
                      defaultMessage="Sorry, no operator available"
                    />
                  </Title.H2>
                )}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

AdvancedTeam.defaultProps = {
  loading: true,
};
AdvancedTeam.propTypes = {
  loading: PropTypes.bool,
};

export default AdvancedTeam;
