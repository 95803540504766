import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Button from './Button';

/**
 * Show a generic error
 * @param error
 * @constructor
 */
function ErrorIndicator({ error }) {
  const history = useHistory();
  return (
    <div>
      <p className="title is-2">
        <FormattedMessage id="app.shared.generic_error" defaultMessage="Sorry, error occurred" />
      </p>
      <p className="subtitle is-5 has-text-centered">{JSON.stringify(error)}</p>
      <div className="columns">
        <div className="column is-5 is-offset-1">
          <Button isFullWidth onClick={() => history.push('/')}>
            <FormattedMessage id="app.shared.home" defaultMessage="Home" />
          </Button>
        </div>
        <div className="column is-5">
          <Button isFullWidth onClick={() => window.location.reload()}>
            <FormattedMessage id="app.shared.retry" defaultMessage="Retry" />
          </Button>
        </div>
      </div>
    </div>
  );
}

ErrorIndicator.propTypes = {
  error: PropTypes.any,
};
export default ErrorIndicator;
