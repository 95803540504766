import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { compose } from 'redux';

import Button from './Button';

const StyledListElement = styled(Button)`
  text-align: left !important;
  margin-bottom: 0.5rem;
  position: relative !important;
  justify-content: initial;
  padding: 2.2rem 2.2rem 2.2rem 8.8rem;
  font-size: 3rem;
`;

const Circle = styled.div`
  background-color: ${({ theme }) => theme.circle || '#fff'};
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 2.2rem;
  position: absolute;
  left: 2.2rem;
  top: 2.2rem;
`;

function ListElement({ children, ...props }) {
  return (
    <StyledListElement isFullWidth {...props}>
      <Circle />
      <>{children}</>
    </StyledListElement>
  );
}

ListElement.defaultProps = {};
ListElement.propTypes = {
  children: PropTypes.node,
};
export default compose(withTheme)(ListElement);
