export const buttons = {
  default: { bg: '#f5f5f5', fg: '#263238' }, // default
  welcome: { bg: '#cfd8dc', fg: '#263238' }, // welcome
  primary: { bg: '#455a64', fg: 'white', fgSecondary: '#b0bec5' }, // is-primary
  blueyGrey: { bg: '#90a4ae', fg: 'white', fgSecondary: '#b0bec5' }, // is-dark
  neutralGrey: { bg: '#b0bec5', fg: 'white' }, // is-dark
  productionBlue: { bg: '#2979ff', fg: 'white' }, // is-info + is-production
  errorRed: { bg: '#f50057', fg: 'white' }, // is-danger
  pauseAzure: { bg: '#03a9f4', fg: 'white' }, // is-pause
  materialViolet: { bg: '#aa00ff', fg: 'white' }, // is-material
  setupYellow: { bg: '#ffab00', fg: 'white' }, // is-warning + is-setup
  checkGreen: { bg: '#00c853', fg: 'white' }, // is-check
  lightBlue: { bg: '#edf4ff', fg: '#263238', circle: '#2979ff' },
  lightRed: { bg: '#fbe8f0', fg: '#263238', circle: '#f50057' },
};
const themes = {
  default: {
    border: '#b0bec5',
    bg: 'white',
    fg: '#263238',
    buttons: buttons.default,
    listElement: buttons.lightBlue,
  },
  primary: {
    border: '#b0bec5',
    bg: 'white',
    fg: '#455a64',
    buttons: buttons.primary,
  },
  secondary: {
    border: '#b0bec5',
    bg: 'white',
    fg: '#78909c',
    buttons: buttons.default,
  },
  welcome: {
    border: '#37474f',
    bg: '#263238',
    fg: 'white',
    buttons: buttons.welcome,
  },
  // Application
  errorRed: {
    border: '#ff1744',
    bg: 'white',
    fg: '#263238',
    buttons: buttons.errorRed,
    listElement: buttons.lightRed,
  },
  productionBlue: {
    border: '#2979ff',
    bg: 'white',
    fg: '#263238',
    buttons: buttons.productionBlue,
  },
  pauseAzure: {
    border: '#03a9f4',
    bg: 'white',
    fg: '#263238',
    buttons: buttons.pauseAzure,
  },
  materialViolet: {
    border: '#aa00ff',
    bg: 'white',
    fg: '#263238',
    buttons: buttons.materialViolet,
  },
  setupYellow: {
    border: '#ffab00',
    bg: 'white',
    fg: '#263238',
    buttons: buttons.setupYellow,
  },
  checkGreen: {
    border: '#00c853',
    bg: 'white',
    fg: '#263238',
    buttons: buttons.checkGreen,
  },
  neutralGrey: {
    border: '#b0bec5',
    bg: 'white',
    fg: '#263238',
    buttons: buttons.default,
  },
  doneGrey: {
    border: '#ebeef0',
    bg: '#a8c6df',
    fg: '#263238',
    buttons: buttons.default,
  },
};

export const statuses = {
  NEW: themes.neutralGrey,
  INTERRUPT: themes.neutralGrey,
  ERROR: themes.errorRed,
  PAUSE: themes.pauseAzure,
  MATERIAL: themes.materialViolet,
  APPROVAL: themes.checkGreen,
  SETUP: themes.setupYellow,
  PRODUCTION: themes.productionBlue,
  CHECK: themes.checkGreen,
  PROBLEM: themes.errorRed,
};

export default themes;
