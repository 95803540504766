import application from './application.reducer';
import config from './config.reducer';
// import machine from './machine.reducer';
import delivery from './delivery.reducer';
import user from './user.reducer';

/**
 * Combine application reducers
 * @type {Reducer<S>}
 */
export default { application, config, delivery, user };
