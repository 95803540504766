import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Protect route checking
 * @param Component
 * @param rest
 * @constructor
 */
function HmiRoute({ component: Component, ...rest }) {
  const { hmiId } = useSelector(({ config }) => config);
  return (
    <Route
      {...rest}
      render={(props) =>
        hmiId ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/errors/not-configured', state: { from: props.location } }} />
        )
      }
    />
  );
}

HmiRoute.propTypes = {
  // component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default HmiRoute;
