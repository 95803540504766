import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';

import Title from '../../../../../components/Title';
import OnScreenKeyboard, { KeyboardButton } from '../../../../../components/OnScreenKeyboard';
import Item from './Item';
import CONTAINER_ITEMS_PAGED from './ContainerItemStep1.query.graphql';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import GenericError from '../../../../errors/GenericError';

function ContainerItemStep1({ container, itemId, setItemId, setStep }) {
  const intl = useIntl();
  const inputEl = useRef(null);
  const { containerId } = useParams();
  const [search, setSearch] = useState('');
  const [showKeyboard, setShowKeyboard] = useState(false);

  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { ON_SCREEN_KEYBOARD } = hmiConfiguration;

  const variables = { containerId, options: { offset: 0, limit: 25 }, search };
  const { loading, error, data } = useQuery(CONTAINER_ITEMS_PAGED, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  function onFocus() {
    if (!ON_SCREEN_KEYBOARD) {
      return;
    }
    setShowKeyboard(true);
    inputEl.current.select();
  }

  function onClick(itemId) {
    setItemId(itemId);
    setStep(2);
  }

  const { result } = data;
  return (
    <>
      {/* HEAD */}
      <div className="columns">
        <div className="column has-text-centered">
          <h1 className="title is-1 w700">
            <FormattedMessage
              id="app.pages.application.container_item.title"
              defaultMessage="Choose item ({value, number} found)"
              values={{ value: result.total }}
            />
          </h1>
        </div>
      </div>
      <div className="columns">
        <div className="column is-6 is-offset-3">
          <input
            type="text"
            className="input is-rounded title is-3 is-double"
            placeholder={intl.formatMessage({
              id: 'app.pages.application.container_item.search.placeholder',
              defaultMessage: 'Search item...',
            })}
            value={search}
            onFocus={onFocus}
            ref={inputEl}
            onInput={(event) => setSearch(event.target.value)}
          />
        </div>
      </div>
      {/* DATA */}
      <div className="columns">
        <div className="column is-10 is-offset-1">
          {result.items.map((item) => (
            <Item key={item._id} item={item} onClick={onClick} selected={itemId || container._item} />
          ))}
          {result.items === 0 && (
            <Title.H2>
              <FormattedMessage
                id="app.pages.application.container_item.item.empty"
                defaultMessage="There is no item, contact responsible"
              />
            </Title.H2>
          )}
        </div>
      </div>
      {showKeyboard && (
        <OnScreenKeyboard
          inputNode={inputEl.current}
          leftButtons={[
            <KeyboardButton
              classes="close-button"
              key={0}
              onClick={() => setShowKeyboard(false)}
              value={intl.formatMessage({
                id: 'app.shared.close',
                defaultMessage: 'Close',
              })}
            />,
          ]}
        />
      )}
    </>
  );
}

ContainerItemStep1.defaultProps = {
  itemId: null,
};
ContainerItemStep1.propTypes = {
  itemId: PropTypes.string,
  setItemId: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    _item: PropTypes.string,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  setStep: PropTypes.func.isRequired,
};

export default ContainerItemStep1;
