import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';

import Button from '../../../components/Button';
import { ThemeProvider } from 'styled-components';
import themes, { buttons } from '../../../libs/themes';
import Title from '../../../components/Title';
import Background from '../../../components/Layout';
import Header from '../../../components/Header';
import { SmallTag } from '../../../components/Tag';
import Subtitle from '../../../components/Subtitle';
import CenteredLayout from '../../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { resetPhase, resetTeam } from '../../../actions';
import PHASE from './End.query.graphql';

function End() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { stationId, hmiConfiguration } = useSelector(({ config }) => config);
  const { CHOOSE_PHASE, OPERATION_SORT: sort } = hmiConfiguration;
  const { phaseId } = useParams();
  const theme = themes.default;
  useEffect(() => {
    dispatch(resetPhase());
    dispatch(resetTeam());
  }, []);
  const where = { 'dependencies._phase': phaseId };
  const { loading, error, data } = useQuery(PHASE, { variables: { stationId, phaseId, sort, where } });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, phases } = data;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>End - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              {/* LOGOUT */}
              <div className="column is-4">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.push('/')}
                  data-cy="application-button-next"
                >
                  <FormattedMessage id="app.pages.application.end.end_session" defaultMessage="End session" />
                </Button>
              </div>
              {/* NEW ORDER */}
              <div className="column is-4">
                <Button
                  isFullWidth
                  theme={themes.primary.buttons}
                  data-cy="application-button-next-order"
                  onClick={() => {
                    if (CHOOSE_PHASE) {
                      return history.push('/configuration/order');
                    }
                    return history.push('/configuration/next-phase');
                  }}
                >
                  <FormattedMessage id="app.pages.application.end.new_order" defaultMessage="New order" />
                </Button>
              </div>
              {/* NEXT OPERATION */}
              {phase && (
                <div className="column is-4">
                  <Button
                    isFullWidth
                    theme={themes.productionBlue.buttons}
                    onClick={() => history.push(`/configuration/definition/${phase.job._id}/${phase._id}`)}
                    data-cy="application-button-next-operation"
                  >
                    <FormattedMessage id="app.pages.application.end.next_phase" defaultMessage="Next operation" />
                  </Button>
                </div>
              )}
            </div>
          }
        >
          <>
            <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
              <FormattedMessage id="app.pages.application.end.tag" defaultMessage="Operation delivered" />
            </SmallTag>
            <Title.H1>
              <FormattedMessage id="app.pages.application.end.dependencies" defaultMessage="Dependencies" />
            </Title.H1>
            {/* DATA */}
            {phases.map((phase) => (
              <>
                <Subtitle.S2 theme={themes.secondary}>
                  <strong>{`${phase.sort} :: ${phase.name}`}</strong>
                  {phase.job && ` ${phase.job.name}`}
                  {phase.product && ` | ${phase.product.code}`}
                </Subtitle.S2>
                <Subtitle.S4 theme={themes.secondary}>
                  <Choose>
                    <When condition={phase.station && phase.area}>
                      <span>
                        <strong>{phase.station.name}</strong> <small>{phase.area.name}</small>
                      </span>
                    </When>
                    <When condition={phase.area}>
                      <span>
                        @ <strong>{phase.area.name}</strong>
                      </span>
                    </When>
                    <When condition={phase.worker && phase.workgroup}>
                      <span>
                        <strong>{phase.worker.name}</strong> <small>{phase.workgroup.name}</small>
                      </span>
                    </When>
                    <When condition={phase.workgroup}>
                      <span>
                        <strong>{phase.workgroup.name}</strong>
                      </span>
                    </When>
                  </Choose>
                </Subtitle.S4>
              </>
            ))}
            {phases.length === 0 && (
              <Subtitle.S2>
                <FormattedMessage
                  id="app.pages.configuration.end.no_dependency"
                  defaultMessage="This was the last operation in order"
                />
              </Subtitle.S2>
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

End.propTypes = {};

export default End;
