import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { ThemeProvider } from 'styled-components';
import { useHistory } from 'react-router-dom';

import Clock from '../../components/Clock';
import Button from '../../components/Button';
import { resetPhase, resetConfig, resetTeam, logout } from '../../actions';
import toast from '../../libs/toast';
import Background from '../../components/Layout/Background';
import themes from '../../libs/themes';
import Header from '../../components/Header';
import CenteredLayout from '../../layouts/CenteredLayout/CenteredLayout.layout';
import LoadingIndicator from '../../components/LoadingIndicator';
import ACTIVE_SESSIONS from './Welcome.query.graphql';

/**
 * Welcome page
 *
 * @returns {*}
 * @constructor
 */
function Welcome() {
  const theme = themes.welcome;
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { hmiId, hmiConfiguration } = useSelector(({ config }) => config);
  const { STATION_ID, MULTI_SESSION } = hmiConfiguration;
  useEffect(() => {
    dispatch(resetConfig());
    dispatch(resetPhase());
    dispatch(resetTeam());
    dispatch(logout());
  }, [dispatch]);
  const variables = { hmiId };
  const { data, loading, error } = useQuery(ACTIVE_SESSIONS, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { sessions } = data;

  function onClick(event) {
    event.preventDefault();
    if (!MULTI_SESSION && sessions.length > 0) {
      toast({
        title: intl.formatMessage({
          id: 'app.pages.welcome.error.not_multisession',
        }),
        icon: 'warning',
      });
      return;
    }
    if (STATION_ID) {
      return history.push('/configuration/operator');
    }
    return history.push('/configuration/station');
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          headHasBorderBottom={false}
          head={
            <Header hasSessionInfo={false} hasClock={false} hasHmiInfo>
              <div className="column has-text-right">
                <Clock className="title is-1 is-double" />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-6 is-offset-3">
                <Button isFullWidth theme={theme.buttons} onClick={onClick} data-cy="welcome-start">
                  <FormattedMessage id="app.pages.welcome.button" defaultMessage="Start" />
                </Button>
              </div>
            </div>
          }
        />
      </ThemeProvider>
    </>
  );
}

export default Welcome;
