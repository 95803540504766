import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import { resetPhase, setMachine, setStation, setUser } from '../../actions';
import addUserImage from '../../images/add-session.svg';
import ACTIVE_SESSIONS from './Sessions.query.graphql';
import ACTIVE_SESSIONS_SUBSCRIPTION from './Sessions.subscription.graphql';
import Session from './components/Session';
import VerifyPin from '../VerifyPin/VerifyPin';
import IdleTimeout from '../../libs/idle-timeout';
import SessionsList from './components/SessionsList';

const SessionsWrapper = styled.div`
  color: ${({ theme }) => theme.fg};
  background-color: ${({ theme }) => theme.order};
  position: absolute;
  left: 0;
  top: 2rem;
  bottom: 2rem;
  height: calc(100vh - 4rem);
  width: 8rem;
  border-radius: 0 0.5rem 0 0.5rem;
  padding: 0 1rem 0;
  display: flex;
  flex-direction: column;
`;

/**
 * Sessions sidebar
 *
 * @param color
 * @returns {*}
 * @constructor
 */
function Sessions({ isDisabled }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [session, setSession] = useState(null);

  const { hmiId, hmiConfiguration } = useSelector(({ config }) => config);
  const { MULTI_SESSION, HMI_TIMEOUT } = hmiConfiguration;

  const { subscribeToMore, ...result } = useQuery(ACTIVE_SESSIONS, {
    variables: { hmiId },
    // fetchPolicy: 'network-only',
    fetchPolicy: 'cache-first',
    // pollInterval: 1000,
  });

  function subscribeToUpdates() {
    return subscribeToMore({
      document: ACTIVE_SESSIONS_SUBSCRIPTION,
      variables: { hmiId },
      updateQuery: function (
        previous,
        {
          subscriptionData: {
            data: { sessions: current },
          },
        }
      ) {
        const { sessions } = current;
        return { sessions };
      },
    });
  }

  function onSessionClick(session) {
    const pins = [session.worker.pin, ...session.team.map(({ pin }) => pin)].filter((pin) => pin && pin.length > 0);
    console.log(pins);
    if (pins.length > 0) {
      return setSession(session);
    }
    onSessionStart(session);
  }

  function onSessionStart(session) {
    console.log('Session start', session);
    dispatch(resetPhase());
    if (!session) {
      return history.push('/');
    }
    if (session.machine) {
      dispatch(setMachine(session.machine._id));
    }
    dispatch(setStation(session.station._id));
    dispatch(setUser(session.operatorToken));
    const { phase } = session;

    IdleTimeout.start({ timeout: HMI_TIMEOUT, callback: onLogout });

    if (phase.configuration.HMI_MODE === 'WORKFLOW') {
      // If new status is pause change HMI path to PAUSE
      switch (session.status) {
        case 'PAUSE':
          return history.push(`/application/pause/${session.phase._id}/${session._id}/${session._step || ''}`);
        case 'PROBLEM':
          return history.push(`/application/problem/${session.phase._id}/${session._id}/${session._step || ''}`);
        default:
          return history.push(`/application/step/${session.phase._id}/${session._id}/${session._step || ''}`);
      }
    }
    if (phase.configuration.HMI_MODE === 'FREE') {
      switch (session.status) {
        case 'MATERIAL':
          return history.push(`/application/material/${session.phase._id}/${session._id}`);
        case 'SETUP':
          return history.push(`/application/setup/${session.phase._id}/${session._id}`);
        case 'APPROVAL':
          return history.push(`/application/approval/${session.phase._id}/${session._id}`);
        case 'PRODUCTION':
          return history.push(`/application/production/${session.phase._id}/${session._id}`);
        case 'CHECK':
          return history.push(`/application/check/${session.phase._id}/${session._id}`);
        case 'PAUSE':
        default:
          return history.push(`/application/menu/${session.phase._id}/${session._id}`);
      }
    }
  }

  function onLogout() {
    IdleTimeout.timer && IdleTimeout.stop();
    history.push('/');
  }

  return (
    <SessionsWrapper>
      {pathname !== '/' && MULTI_SESSION && (
        <Session onClick={onLogout} data-cy="components-sessions-logout">
          <figure className="image is-32x32">
            <img src={addUserImage} alt="" />
          </figure>
        </Session>
      )}
      <SessionsList
        onLogout={onLogout}
        onSessionClick={onSessionClick}
        isDisabled={isDisabled}
        subscribeToUpdates={subscribeToUpdates}
        {...result}
      />
      {session && (
        <VerifyPin
          title={(session && session.worker.name) || 'N/A'}
          verify={(pin) => session && [session.worker.pin, ...session.team.map(({ pin }) => pin)].includes(pin)}
          show={!!session}
          onSuccess={() => onSessionStart(session)}
          onCancel={() => setSession(null)}
        />
      )}
    </SessionsWrapper>
  );
}

Sessions.defaultProps = {};
Sessions.propTypes = {
  isDisabled: PropTypes.bool,
};

export default Sessions;
