import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-apollo';

import onError from '../../libs/error-logger';
import toast from '../../libs/toast';
import SEND_TO_SMIRO from './SendPhaseToSmiro.mutation.graphql';
import { setLoading } from '../../actions';

function SendPhaseToSmiro({ children }) {
  const intl = useIntl();
  const [mutate] = useMutation(SEND_TO_SMIRO);
  const dispatch = useDispatch();

  async function onClick(phaseId) {
    dispatch(setLoading(true));
    try {
      await mutate({ variables: { phaseId } });
      toast({
        title: intl.formatMessage({
          id: 'app.components.send_phase_to_smiro.success',
          defaultMessage: 'Picking list was successfully sent to Smiro',
        }),
      });
    } catch (error) {
      onError(error);
    }
    setTimeout(() => dispatch(setLoading(false)), 500);
  }

  return children(onClick);
}

SendPhaseToSmiro.defaultProps = {};
SendPhaseToSmiro.propTypes = {
  children: PropTypes.func.isRequired,
};
export default SendPhaseToSmiro;
